// Mixin shared between all HappyCab projects and always loaded
// For project-specific mixins, refer to mixins/boot
import { useLogger } from 'hc-core/composables/logger.js'
import { useNotify } from 'hc-core/composables/useNotify.js'
import { pickFirstKey } from 'hc-core/composables/misc.js'
import { $app, $vdc, $web } from 'hc-core/composables/routing.js'
import { getImageUrl, getWebpImageUrl, getCroppedImageUrl, cdnImg } from 'hc-core/mixins/aws.js'

const { notifyInfo, notifyError, notifySuccess, notifyWarning } = useNotify()

export default {
  computed: {
    common () { return this.$store.state.common },
  },
  methods: {
    // Imports
    $app,
    $vdc,
    $web,
    cdnImg,
    useLogger,
    pickFirstKey,
    getImageUrl,
    getWebpImageUrl,
    getCroppedImageUrl,

    // Notify
    notifyInfo,
    notifyError,
    notifySuccess,
    notifyWarning,

    // CustomAttributes
    getCustomAttributeValues (customAttribute) {
      const array = []
      for (const id in this.common.customAttributesById) {
        if (this.common.customAttributesById[id].name === customAttribute) {
          this.common.customAttributesById[id].listValues.forEach(item => array.push({
            label: this.$t({ id: `customAttributes.${customAttribute}.${item}` }),
            value: item
          }))
          break
        }
      }
      return array
    },

    // Internationalization
    translationExists (string) {
      const t = this.$t({ id: string })
      return string && t && t !== string && t !== '' && t !== undefined
    },
  },
}
