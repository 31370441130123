<template>
  <div id="pdfgeneratorid" class="fit q-pa-md">
    <img :src="cdnImg($t('images.shape_1'))" loading="eager" style="z-index:-1; top: 0px; left: 0px;" height="200" class="absolute nodrag nopointers">
    <img :src="cdnImg($t('images.shape_2'))" loading="eager" style="z-index:-1; top: 40px; right: 15px;" height="200" class="absolute nodrag nopointers">
    <img :src="cdnImg($t('images.shape_4'))" loading="eager" style="z-index:-1; bottom: 0px; left: 0px;" height="100" class="absolute nodrag nopointers">
    <img :src="cdnImg($t('images.shape_3'))" loading="eager" style="z-index:-1; bottom: 10px; right: 10px; max-width: 40vw;" height="100" class="absolute nodrag nopointers">

    <div v-if="entities.company" class="float-right column items-end">
      <img src="https://happycab.s3.eu-west-3.amazonaws.com/live/platform/documents/Logo_HappyCab_x_VDC.png" width="150" loading="eager">
      <div class="text-body1 text-grey-5">
        {{ entities.company.name }}
      </div>
      <img :src="`https://happycab.s3.eu-west-3.amazonaws.com/live/${entities.company.metadata._files.logo}`" width="50" height="50" loading="eager" class="br-50">
    </div>
    <div v-if="context.title" class="text-h5 text-primary">
      {{ context.title }}
    </div>

    <!-- Table on top -->
    <table v-if="params.pdfCase === 'meetingSynthesis'" class="q-mt-md bd-grey-4" style="border-collapse: collapse;">
      <tr>
        <td class="bd-1 bd-grey-4 q-pa-sm">
          <span class="text-weight-bold">
            Nom et prénom du salarié :
          </span>
          {{ $_.get(entities, 'document.metadata._private.lastname', 'Nom non renseigné') }}
          {{ $_.get(entities, 'document.metadata._private.firstname', 'Prénom non renseigné') }}
        </td>
        <td class="bd-1 bd-grey-4 q-pa-sm">
          <span class="text-weight-bold">
            Nom et prénom du manager :
          </span>
          {{ $_.get(entities, 'document.metadata.meeting.ownerLastname', 'Nom non renseigné') }}
          {{ $_.get(entities, 'document.metadata.meeting.ownerFirstname', 'Prénom non renseigné') }}
        </td>
      </tr>
      <tr>
        <td class="bd-1 bd-grey-4 q-pa-sm">
          <span class="text-weight-bold">
            Poste et ancienneté du salarié :
          </span>
          {{ $_.get(entities, 'document.metadata._private.job', 'Poste non renseigné') }}
          ({{ $_.get(entities, 'document.metadata._private.job_seniority', 'Ancienneté non renseigné') }})
        </td>
        <td class="bd-1 bd-grey-4 q-pa-sm">
          <span class="text-weight-bold">
            Poste du manager :
          </span>
          {{ $_.get(entities, 'document.metadata.meeting.ownerJob', 'Poste non renseigné') }}
        </td>
      </tr>
    </table>

    <!-- Applicant infos on top -->
    <div v-else-if="params.pdfCase === 'resumeGenerator'" class="flex items-center q-mt-md">
      <img
        v-if="$_.get(entities, 'user.metadata._files.avatar')"
        :src="`https://happycab.s3.eu-west-3.amazonaws.com/live/${entities.user.metadata._files.avatar}`"
        width="100"
        height="100"
        loading="eager"
        class="br-50 q-mr-md"
      >
      <div class="col column">
        <div class="text-h3 text-primary">
          {{ $_.get(entities, 'profile.name', 'Nom non renseigné') }}
        </div>
        <div v-if="$_.get(entities, 'profile.customAttributes.jobReferential')" class="text-h6 text-grey-5">
          {{ $t({ id: `customAttributes.jobReferential.${entities.profile.customAttributes.jobReferential}` }) }}
        </div>
        <div class="flex items-center justify-between">
          <div v-if="$_.get(entities, 'profile.metadata._resume.phone')">
            <span class="text-weight-bold">Téléphone :</span>
            {{ entities.profile.metadata._resume.phone }}
          </div>
          <div v-if="$_.get(entities, 'profile.locations[0]')">
            <span class="text-weight-bold">Adresse :</span>
            {{ getLocationLabel(entities.profile.locations[0]) }}
          </div>
        </div>
        <div class="flex items-center justify-between">
          <div v-if="$_.get(entities, 'profile.metadata._resume.email')">
            <span class="text-weight-bold">Email :</span>
            {{ entities.profile.metadata._resume.email }}
          </div>
          <div
            v-if="$_.get(entities, 'profile.metadata._resume.links.linkedin')"
            class="flex items-center"
          >
            <span class="text-weight-bold">LinkedIn</span>
            <QIcon
              name="uil:linkedin-alt"
              class="q-mx-sm"
            />
            <a
              :href="entities.profile.metadata._resume.links.linkedin"
              target="_blank"
              class="hc-link"
            >
              {{ entities.profile.metadata._resume.links.linkedin }}
            </a>
          </div>
        </div>
        <!-- Need links.linkedin -->
      </div>
    </div>

    <!-- Generic fields -->
    <template
      v-for="(field, iField) of fields"
      :key="iField"
    >
      <QSeparator v-if="field.type === 'horizontalLine'" :class="field.class ?? 'q-my-md'" />
      <ul v-else-if="field.type === 'list'">
        <li
          v-for="(item, index) of field.items"
          :key="index"
        >
          <span v-if="item.label" class="text-weight-bold">{{ item.label }} :</span>
          {{ item.value }}
        </li>
      </ul>
      <div v-else-if="Object.keys(textConfig).includes(field.type)" :class="`${textConfig[field.type]} ${field.class ?? ''}`">
        {{ field.value }}
        <div v-if="field.rightChilds" class="float-right align-end">
          <div v-for="(child, iChild) of field.rightChilds" :key="iChild" :class="textConfig[child.type]">
            {{ child.value }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js'
import { isoToMask } from 'hc-core/composables/time.js'
import { keyAfterUploadS3 } from 'hc-core/composables/aws.js'
import { base64Decoder } from 'hc-core/composables/routing.js'
import { getLocationLabel, hexToEmo } from 'hc-core/composables/misc.js'

export default {
  props: {
    propPdfConfig: {
      type: Object,
      reduired: true,
      default: () => {}
    }
  },
  emits: ['finished'],
  data () {
    return {
      entities: {},
      params: {
        pdfCase: 'meetingSynthesis',
      },

      pdfCases: {
        meetingSynthesis: {
          title: 'Synthèse d\'entretien professionnel'
        },
        resumeGenerator: {
          // title: 'Curriculum Vitae'
        }
      },

      textConfig: {
        title: 'avoid-break row full-width flex justify-between text-h6 text-secondary q-my-sm',
        legend: 'avoid-break row full-width text-body2 text-grey-5 q-mb-xs',
        subtitle: 'avoid-break row full-width flex justify-between text-subtitle1 text-black',
        text: 'avoid-break row full-width text-black q-mb-md',
        rightText: 'avoid-break text-body2 text-grey-5 text-right',
      },

      fields: [],
    }
  },
  computed: {
    context () { return this.pdfCases[this.params.pdfCase] }
  },
  async created () {
    // Fetching related data
    this.params = this.propPdfConfig ?? this.$route.query
    if (Object.keys(this.params).includes('encoded')) {
      this.params = {
        ...this.params,
        ...base64Decoder(this.params.encoded)
      }
    }

    for (const [key, value] of Object.entries(this.params)) {
      let entity = null
      switch (key) {
        case 'document': entity = await this.$store.dispatch('document/read', { id: value }); break
        case 'company': entity = await this.$store.dispatch('asset/read', { assetId: value }); break
        case 'profile': entity = await this.$store.dispatch('asset/read', { assetId: value }); break
        case 'user': entity = await this.$store.dispatch('user/read', { id: value }); break
      }
      this.$_.set(this.entities, key, entity)
    }

    if (this.params.pdfCase === 'meetingSynthesis') {
      const blocksFields = this.entities.document.data.blocks.map(b => [
        { type: 'legend', value: b.label },
        ...(b.type === 'text'
          ? [{ type: 'text', value: b.value }]
          : b.type && ['radio', 'select', 'checkbox'].includes(b.type)
            ? [{ type: 'text', value: b.options.filter(o => [...(Array.isArray(b.value) ? b.value : [b.value])].includes(o.value)).map(o => o.label).join(', ') }]
            : []
        ),
        { type: 'newLine' }
      ]).flat().filter(Boolean)

      this.fields = [
        // Quest
        { type: 'horizontalLine' },
        {
          type: 'subtitle',
          value: 'Questionnaire préalable',
          rightChilds: [
            { type: 'rightText', value: 'Envoyé : {document.metadata._communication.sentDate} par {document.metadata.sender.lastname} {document.metadata.sender.firstname}' },
            { type: 'rightText', value: 'Répondu : {document.data.submittedAt}' },
          ]
        },
        { type: 'newLine' },
        { type: 'legend', value: 'Contexte' },
        { type: 'text', value: '{document.data.context}' },
        { type: 'newLine' },
        { type: 'legend', value: 'Message de fin' },
        { type: 'text', value: '{document.data.finishedMessage}' },
        { type: 'newLine' },
        ...blocksFields,

        // Meeting
        { type: 'horizontalLine' },
        {
          type: 'subtitle',
          value: 'Entretien',
          rightChilds: [
            { type: 'rightText', value: 'Date et heure de l\'entretien : {document.metadata.meeting.finishedAt}' },
          ]
        },
        { type: 'newLine' },
        { type: 'legend', value: 'Les perspectives d\'évolution' },
        { type: 'text', value: '{document.metadata.meeting.ownerEvolution}' },
        { type: 'newLine' },
        { type: 'legend', value: 'La synthèse du manager' },
        { type: 'text', value: '{document.metadata.meeting.ownerSynthesis}' },
        { type: 'newLine' },
      ]
    } else if (this.params.pdfCase === 'resumeGenerator') {
      const mappedExperiences = this.$_.flatten(
        this.$_.get(this.entities, 'profile.metadata._resume.experiences', []).map((i) => {
          return [
            {
              type: 'subtitle',
              value: `${i.title ? `${i.title} - ` : ''}${i.org ?? ''}`,
              rightChilds: [
                { type: 'rightText', value: `${i.startDate ? isoToMask(i.startDate, 'MM/YYYY') : ''} - ${i.endDate ? isoToMask(i.endDate, 'MM/YYYY') : ''}` },
              ]
            },
            { type: 'legend', value: getLocationLabel(i.location), if: getLocationLabel(i.location) !== '-' },
            { type: 'text', value: i.description, class: 'q-mb-sm' },
          ]
        })
      )
      const mappedFormations = this.$_.flatten(
        this.$_.get(this.entities, 'profile.metadata._resume.education', []).map((i) => {
          return [
            {
              type: 'subtitle',
              value: `${i.title ? `${i.title} - ` : ''}${i.org ?? ''}`,
              rightChilds: [
                { type: 'rightText', value: `${i.startDate ? isoToMask(i.startDate, 'MM/YYYY') : ''} - ${i.endDate ? isoToMask(i.endDate, 'MM/YYYY') : ''}` },
              ]
            },
            { type: 'legend', value: getLocationLabel(i.location) },
          ]
        })
      )
      const mappedInformations = [
        {
          type: 'list',
          items: [
            {
              label: 'Soft-Skills',
              if: this.$_.get(this.entities, 'profile.metadata._resume.skills', []).length,
              value: this.$_.get(this.entities, 'profile.metadata._resume.skills', []).filter(i => i.type === 'soft_skill').map((i) => {
                return i.title
              }).join(', ')
            },
            {
              label: 'Hard-Skills',
              if: this.$_.get(this.entities, 'profile.metadata._resume.skills', []).length,
              value: this.$_.get(this.entities, 'profile.metadata._resume.skills', []).filter(i => i.type === 'hard_skill').map((i) => {
                return i.title
              }).join(', ')
            },
            {
              label: 'Compétences comptabilité',
              if: this.$_.get(this.entities, 'profile.customAttributes.skillsReferential', []).length,
              value: this.$_.get(this.entities, 'profile.customAttributes.skillsReferential', []).map((i) => {
                return this.$t({ id: `customAttributes.skillsReferential.${i}` })
              }).join(', ')
            },
            {
              label: 'Outils',
              if: this.$_.get(this.entities, 'profile.customAttributes.tools', []).length,
              value: this.$_.get(this.entities, 'profile.customAttributes.tools', []).map((i) => {
                return this.$t({ id: `customAttributes.tools.${i}` })
              }).join(', ')
            },
            {
              label: 'Langues',
              if: this.$_.get(this.entities, 'profile.metadata._resume.languages', []).length,
              value: this.$_.get(this.entities, 'profile.metadata._resume.languages', []).map((i) => {
                return `${i.emoji ? hexToEmo(i.emoji) : ''} ${i.title ?? ''} ${i.level ? `- ${i.level}` : ''}`
              }).join(', ')
            },
            {
              label: 'Certifications',
              if: this.$_.get(this.entities, 'profile.metadata._resume.certifications', []).length,
              value: this.$_.get(this.entities, 'profile.metadata._resume.certifications', []).join(', ')
            },
            {
              label: 'Références',
              if: this.$_.get(this.entities, 'profile.metadata._resume.referees', []).length,
              value: this.$_.get(this.entities, 'profile.metadata._resume.referees', []).map((i) => {
                return `${i.name ? `${i.name} - ` : ''} ${i.org ?? ''} (${i.email ?? ''}, ${i.phone})`
              }).join(', ')
            },
          ].filter(f => this.$_.get(f, 'if', true))
        },

      ]

      this.fields = [
        // Summary
        { type: 'title', value: 'Résumé', class: 'q-mt-md' },
        { type: 'horizontalLine', class: 'q-my-xs' },
        { type: 'text', value: '{profile.description}' },
        // Work experience
        {
          type: 'title',
          value: 'Expérience professionnelle',
          class: 'q-mt-md',
          rightChilds: [
            {
              type: 'rightText',
              value: this.$t('profile.accountantBackground.active'),
              if: this.$_.get(this.entities, 'profile.customAttributes.accountantBackground', false)
            },
          ].filter(i => this.$_.get(i, 'if', true))
        },
        { type: 'horizontalLine', class: 'q-my-xs' },
        ...mappedExperiences,
        // Formation
        { type: 'title', value: 'Formation', class: 'q-mt-md' },
        { type: 'horizontalLine', class: 'q-my-xs' },
        ...mappedFormations,
        // Formation
        { type: 'title', value: 'Informations', class: 'q-mt-md' },
        { type: 'horizontalLine', class: 'q-my-xs' },
        ...mappedInformations,
      ]
    }

    if (this.fields.length) {
      this.fields = this.fields.map((f) => {
        if (f.rightChilds) {
          this.$_.set(f, 'rightChilds', f.rightChilds.map(c => this.$_.set(c, 'value', this.replacePlaceholders(c.value))))
        }
        return f.value ? this.$_.set(f, 'value', this.replacePlaceholders(f.value)) : f
      }).filter(f => this.$_.get(f, 'if', true))
    }

    setTimeout(() => {
      this.$nextTick(() => { this.generatePDF() })
    }, 500)
  },
  methods: {
    getLocationLabel,
    replacePlaceholders (value) {
      // Use a regular expression to match placeholders like {entity.property}
      const regex = /\{([^}]+)\}/g

      // Replace each placeholder with its corresponding value from the entities object
      const replacedValue = value.replace(regex, (match, key) => {
        const keys = key.split('.')
        let currentValue = this.entities

        for (const k of keys) {
          if (currentValue && this.$_.get(currentValue, k, false)) {
            currentValue = currentValue[k]
          } else {
            // Placeholder not found or value is undefined, return an empty string
            return ''
          }
        }

        // Check if the current value is a string and matches ISO date format
        if (typeof currentValue === 'string' && /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(currentValue)) {
          return currentValue.replace(currentValue, this.$t({ id: 'time.datetime' }, { fmtd: currentValue }))
        }

        return currentValue !== undefined ? currentValue : ''
      })

      return replacedValue
    },
    generatePDF () {
      try {
        // Get the element you want to convert to PDF
        const element = document.getElementById('pdfgeneratorid')

        // Configuration for html2pdf
        const options = {
          margin: 10,
          filename: 'generated.pdf',
          image: { type: 'png', quality: 1 },
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
          html2canvas: { scale: 2, useCORS: true, 'background-color': '#262338' },
          jsPDF: { unit: 'mm', format: 'a4', compress: true, orientation: 'portrait' },
        }

        // DEV : If we want to download it
        if (this.params.dev || Object.keys(this.$route.query).includes('dev')) {
          html2pdf(element, options).then(pdf => {
            const blob = pdf.blob()
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = options.filename
            link.click()
          })
        } else {
          if (this.params.uploadConfig) {
            // Generate PDF using html2pdf and upload it
            html2pdf()
              .set(options)
              .from(element)
              .toPdf()
              .output('blob')
              .then(async (data) => {
                try {
                  const uploadConfig = {
                    id: this.$_.get(this.entities, `${this.params.uploadConfig.entityKey}.id`, null),
                    entity: this.$_.get(this.entities, this.params.uploadConfig.entityKey, null),
                    uploadFolder: this.$_.get(this.params, 'uploadConfig.uploadFolder', undefined),
                    uploadPrefix: this.$_.get(this.params, 'uploadConfig.uploadPrefix', undefined),
                    contentType: 'application/pdf',
                    useEntityId: this.$_.get(this.params, 'uploadConfig.useEntityId', undefined),
                    field: this.$_.get(this.params, 'uploadConfig.field', undefined),
                    action: this.$_.get(this.params, 'uploadConfig.action', undefined),
                  }

                  const fileKey = await keyAfterUploadS3({
                    file: new File([data], 'test.pdf', { type: 'application/pdf' }), // data is a Blob type
                    entity: this.$_.get(uploadConfig, 'entity', undefined),
                    options: uploadConfig
                  })

                  if (fileKey) {
                    const uploaded = {}
                    this.$_.set(uploaded, `attrs.${uploadConfig.field}`, fileKey)
                    this.$_.set(uploaded, 'id', uploadConfig.id)
                    if (uploaded && uploadConfig.action) {
                      const res = await this.$store.dispatch(uploadConfig.action, uploaded)
                      this.$emit('finished', res)
                    }
                  } else this.notifyError()
                } catch (e) {
                  console.error(e)
                }
              })
          } else {
            html2pdf(element, options).then(pdf => {
              const blob = pdf.blob()
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = options.filename
              link.click()
            })
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
  }
}
</script>

<style lang="sass" scoped>
.avoid-break
  page-break-inside: avoid

@page
  width: 21cm
  height: 29.7cm
  margin: 27mm 16mm 27mm 16mm

@media print
  body
    width: 21cm
    height: 29.7cm
    /* margin: 30mm 45mm 30mm 45mm */
    margin: 0mm
    /* change the margins as you want them to be. */
</style>
