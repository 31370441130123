export default function () {
  return {
    // Data
    element: null,
    applications: [],
    applicants: [],
    profileAssets: [],
    conversations: [],

    // Kanban
    stages: [],
    embed: false,

    orderByField: 'createdDate',
    orderByOrder: 'desc',
    filterUnread: [],
    stageModel: null,
    activeModel: true,
  }
}
