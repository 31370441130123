import { get } from 'lodash'
import { useStore } from 'vuex'
import { computed } from 'vue'

// Mainly to replace roles.js mixins
export function useRoles () {
  const store = useStore()

  // Computed
  const currentUser = computed (() => store.getters['auth/currentUser'])
  const userOrganizations = computed (() => store.getters['auth/userOrganizations'])
  const currentNaturalUser = computed (() => store.getters['auth/currentNaturalUser'])

  // Old way, maybe be slowly replaced by usePerm
  const hasRole = (user, role) => {
    return user && role && get(user, 'roles', []).includes(role)
  }

  // Only in organization context
  const isSubscribedOrganization = () => {
    return get(userOrganizations.value, 'subscription', false)
  }

  // General roles
  const isApplicant = (user = currentNaturalUser.value) => {
    return hasRole(user, 'applicant')
  }

  const isOrganization = (user = currentUser.value) => {
    return hasRole(user, 'organization')
  }

  const isClient = (user = currentNaturalUser.value) => {
    return hasRole(user, 'client') || isSubscribedOrganization()
  }

  const isPhenix = (user = currentNaturalUser.value) => {
    return hasRole(user, 'phenix')
  }

  const isDragon = (user = currentNaturalUser.value) => {
    return hasRole(user, 'dragon')
  }

  const isStarter = (user = currentNaturalUser.value) => {
    return hasRole(user, 'starter')
  }

  const isEssential = (user = currentNaturalUser.value) => {
    return hasRole(user, 'essential')
  }

  const isPremium = (user = currentNaturalUser.value) => {
    return hasRole(user, 'premium')
  }

  return {
    // Computed
    currentUser,
    userOrganizations,
    currentNaturalUser,

    // Functions
    isApplicant,
    isOrganization,
    isClient,
    isPhenix,
    isDragon,
    isStarter,
    isEssential,
    isPremium
  }
}
