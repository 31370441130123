<template>
  <div ref="Chat" class="fit column q-px-md">
    <div class="col">
      <div v-if="conversation && $_.get(conversation, 'messages.length', 0) > 0" id="scrollAreaId" ref="scrollAreaRef" :key="loading" style="overflow-y: scroll; max-height:100%">
        <QChatMessage
          v-for="(msg, index) of computedConversation"
          :key="index"
          text-html
          :text="[msg.content]"
          :sent="msg.sent"
          :stamp="msg.stamp"
          :bg-color="msg.bgColor"
        >
          <template #avatar>
            <AppAvatar :entity="msg.avatar" size="42px" content-class="q-mx-sm" />
          </template>
        </QChatMessage>
        <HCLoading :showing="loading" inner />
      </div>
      <div v-else class="full-width row flex-center text-negative q-gutter-sm">
        <QIcon size="2em" name="uil:confused" />
        <AppContent path="error.no_data" />
      </div>
    </div>
    <div class="col-auto flex items-center no-wrap bg-white" style="z-index: 10;">
      <div class="full-width items-center no-wrap">
        <QSelect
          v-if="isSubscribed()"
          v-model="selectedTemplate"
          :option-label="'name'"
          :option-value="'id'"
          :options="$_.concat(defaultTemplates, userTemplates)"
          class="q-my-sm"
          :label="$t('component.dialogs.application_mail_dialog.templates')"
          dense
          outlined
          :bg-color="`${selectedTemplate ? templateTypes.find(({ value }) => value === selectedTemplate.label).color : 'info' ?? 'info'}-light`"
          :display-value="$_.get(selectedTemplate, 'data.name', $_.get(selectedTemplate, 'name', undefined))"
          @update:model-value="updateTemplate"
        >
          <template #option="scope">
            <QItem
              v-bind="scope.itemProps"
              v-on="scope.itemEvents"
              :class="`bg-${templateTypes.find(({ value }) => value === scope.opt.label).color ?? 'info'}-light`"
            >
              <QItemSection>
                <div class="text-weight-medium">
                  {{ scope.opt.name ?? scope.opt.data.name ?? 'Label non défini' }}
                </div>
              </QItemSection>
              <QItemSection avatar>
                <QChip
                  v-if="scope.opt.label"
                  :class="templateTypes.find(({ value }) => value === scope.opt.label).chipClass"
                  :label="$t({ id: `component.templates.types_short.${scope.opt.label}` })"
                />
              </QItemSection>
            </QItem>
          </template>
        </QSelect>
        <QEditor
          ref="editorRef"
          v-model="newMessage"
          :min-height="editorHeight"
          class="full-width"
          :placeholder="$t({ id: 'pages.messages.write_to' }, { name: $_.get(conversation, 'interlocutor.displayName', 'votre interlocuteur') })"
          :definitions="computedDefinitions"
          :toolbar="[
            ['bold', 'italic', 'strike', 'underline'],
            ['unordered', 'ordered'],
            ['charsCounter'],
            ['toggleEditorHeight']
          ]"
          @paste="onPaste"
        />
      </div>
      <div class="q-ml-sm flex column q-gutter-y-sm">
        <HCMenu
          :actions="[
            { icon: 'uil:envelope', label: 'prompt.conversation_mark_as_unread.title', fn: async (i) => { await convMarkUnread(i) }},
            { icon: 'uil:archive', color: 'warning', label: 'prompt.conversation_archive.title', fn: async (i) => { await convArchive(i) }},
          ]"
          :element="conversation"
        />
        <QBtn :loading="loading" :disabled="loading || newMessage.length === 0" icon="uil:message" round color="primary" size="14px" @click="sendMessage({ isScheduled: false })">
          <ActionTooltip path="prompt.send_button" no-wrap />
        </QBtn>
        <QBtn :loading="loading" :disabled="loading || newMessage.length === 0" icon="uil:clock" round color="secondary" size="14px" @click="scheduleDialog = true">
          <ActionTooltip path="prompt.schedule_send_button" no-wrap />
        </QBtn>
        <QBtn v-if="$_.get(conversation, 'scheduledToSend.length', false)" icon="uil:mailbox" round color="secondary" size="14px" @click="scheduledDialog = true">
          <ActionTooltip path="prompt.scheduled_show_button" no-wrap />
        </QBtn>
      </div>
    </div>
    <QDialog v-model="scheduleDialog" :maximized="$q.screen.lt.sm" @hide="scheduleDialog = false">
      <AccountCard path="component.dialogs.application_mail_dialog.send_message" icon="uil:fast-mail">
        <template #header-right>
          <div class="flex items-center gt-xs">
            <HCButton v-close-popup is-close />
          </div>
        </template>
        <QCardSection>
          <div class="full-width flex justify-around">
            <Datetime
              v-model="scheduledTimestamp"
              hint="ui.datetime.hint.week_8_18"
              :date-options="optionsDate"
              :date-rules="[
                val => {
                  return optionsDate(val) || $t('ui.datetime.error.incorrect_date_input')
                }
              ]"
              :time-rules="[
                val => {
                  const [hours, minutes] = val.split(':').map(Number)
                  // Check if the time is between 08:00 and 18:00
                  const timeInMinutes = hours * 60 + minutes
                  const minTimeInMinutes = 8 * 60 // 08:00 in minutes
                  const maxTimeInMinutes = 18 * 60 // 18:00 in minutes
                  if (timeInMinutes < minTimeInMinutes || timeInMinutes > maxTimeInMinutes) {
                    return $t({ id: 'ui.datetime.error.authorized_time_range' }, { from: '08h00', to: '18h00' })
                  }
                  return true
                }
              ]"
              @validation="scheduledValidation = $event"
            />
          </div>
          <div class="full-width flex justify-between q-mt-md">
            <HCBigButton color="negative" label="prompt.cancel_button" @click="scheduleDialog = false" />
            <HCBigButton color="positive" label="prompt.schedule_send_button" :disable="!scheduledTimestamp || new Date(scheduledTimestamp) <= new Date() || !scheduledValidation" @click="sendMessage({ isScheduled: true }); scheduleDialog = false" />
          </div>
        </QCardSection>
      </AccountCard>
    </QDialog>
    <QDialog v-model="scheduledDialog" :maximized="$q.screen.lt.sm" @hide="scheduledDialog = false">
      <AccountCard path="prompt.scheduled_show_button" icon="uil:mailbox" style="min-width:80%;">
        <template #header-right>
          <div class="flex items-center gt-xs">
            <HCButton v-close-popup is-close />
          </div>
        </template>
        <QCardSection>
          <QList dark bordered separator>
            <QItem v-for="msg of $_.sortBy($_.clone(conversation.scheduledToSend), ['metadata.scheduledTimestamp', 'desc'])" :key="msg.id" v-ripple clickable class="bg-secondary-light">
              <QItemSection>
                <QItemLabel class="text-grey-7">
                  <!-- eslint-disable vue/no-v-html -->
                  <div v-html="msg.content" />
                  <!-- eslint-enable vue/no-v-html -->
                </QItemLabel>
                <QItemLabel class="text-grey-4" caption>
                  <AppContent path="time.datetime" :options="{ fmtd: $_.get(msg, 'metadata.scheduledTimestamp', $_.get(msg, 'createdDate')) }" />
                </QItemLabel>
              </QItemSection>
              <QItemSection top side>
                <HCBigButton color="negative" label="prompt.cancel_button" @click="deleteMessage(msg); scheduledDialog = false" />
              </QItemSection>
            </QItem>
          </QList>
        </QCardSection>
      </AccountCard>
    </QDialog>
  </div>
</template>

<script>
import { $t } from 'hc-core/composables/intl'
import { pickFirstKey, stripTags } from 'hc-core/composables/misc.js'
import TemplatesMixins from 'hc-core/mixins/templates'
import { manipulateTime } from 'hc-core/composables/time.js'
import { Datetime } from 'hc-core/components/ui/datetime'
import HCMenu from 'hc-core/components/common/hc-menu'
import { useMessages } from 'hc-core/composables/useMessages'

export default {
  components: { HCMenu, Datetime },
  mixins: [TemplatesMixins],
  setup () {
    const { conversationMarkAsUnread, conversationArchive } = useMessages()
    return { conversationMarkAsUnread, conversationArchive }
  },
  props: {
    conversation: {
      type: Object,
      required: true,
    },
    application: {
      type: Object,
      required: false,
      default: () => {}
    },
    applicant: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  emits: ['refresh'],
  data () {
    return {
      loading: false,
      newMessage: '',
      scheduledTimestamp: null,
      scheduledValidation: false,
      scheduleDialog: false,
      scheduledDialog: false,
      editorOptions: {
        hideModeSwitch: true,
        minHeight: '120px',
        maxHeight: '400px',
        toolbarItems: ['bold', 'italic', 'strike', 'divider', 'ul', 'ol']
      },
      editorHeight: '5rem',
      selectedTemplate: null,
      userTemplates: [],
      defaultTemplates: [],
    }
  },
  computed: {
    talkAssets () { return this.$store.getters['inbox/talkAssets'] },
    usersById () { return this.$store.getters['inbox/inbox'].usersById },
    usersBank () {
      return this.$_.union(
        [this.currentNaturalUser, this.currentUser],
        this.$uElements('organizations')
      )
    },
    computedDefinitions () {
      return {
        charsCounter: {
          tip: $t('component.tools.chat.chars'),
          icon: 'uil:text-size',
          label: `${stripTags(this.newMessage).length} / 3000`,
        },
        toggleEditorHeight: {
          icon: 'uil:expand-alt',
          label: `${this.editorHeight === '5rem' ? 'Agrandir' : 'Réduire'}${this.$q.screen.gt.sm ? ' la zone de saisie' : ''}`,
          handler: () => {
            if (this.editorHeight === '5rem') this.editorHeight = '25rem'
            else if (this.editorHeight === '25rem') this.editorHeight = '5rem'
          }
        }
      }
    },
    computedConversation () {
      // Since many remaps are needed, already map them here to ease rendering
      // bgcolor
      const clonedConversation = this.$_.sortBy(this.$_.clone(this.conversation.messages), ['createdDate', 'asc'])
      for (const msg of clonedConversation) {
        const isMessageSent = this.isApplicant() ? msg.senderId.startsWith('usr_') && msg.senderId === this.currentUser.id : msg.senderId.startsWith('org_')
        const stamp = $t(
          { id: `pages.messages.${isMessageSent ? 'sent_at' : 'received_at'}` },
          {
            datetime: $t(
              { id: 'time.datetime' },
              { fmtd: pickFirstKey(msg, ['metadata.scheduledTimestamp', 'createdDate']) }
            )
          }
        )
        let avatar
        if (this.isApplicant()) {
          avatar = this.$_.get(this.conversation, 'companyAsset.ownerId', null) === msg.senderId ? this.conversation.companyAsset : this.usersBank.find(u => u.id === msg.senderId)
        } else {
          // As client, come from my asset
          if (this.usersBank.find(u => u.id === msg.senderId)) avatar = this.userCompany
          else avatar = this.conversation.interlocutor
        }
        const bgColor = ((this.isApplicant() && msg.senderId.startsWith('usr_') && msg.senderId === this.currentUser.id) || (this.isOrganization() && msg.senderId.startsWith('org_'))) ? 'purple-3' : 'grey-3'

        // Then apply
        msg.sent = isMessageSent
        msg.stamp = stamp
        msg.avatar = avatar
        msg.bgColor = bgColor
      }
      return clonedConversation
    }
  },
  watch: {
    conversation: {
      handler: async function (conversation, oldConversation) {
        if (conversation) {
          this.scrollToBottom()
          if (this.$_.get(conversation, 'messages', []).filter(m => !m.read).length && this.$route.name === 'offer') {
            await this.$store.dispatch('kanban/updateItem', { type: 'conversations', conversation, convMarkRead: true })
          }
        }
      }
    },
  },
  async mounted () {
    try {
      if (process.env.CLIENT) {
        this.scrollToBottom()
        // Set scheduledTimestamp to tommorow 8:00AM
        const d = new Date()
        d.setDate(new Date().getDate() + 1)
        d.setHours(8)
        d.setMinutes(0)
        this.scheduledTimestamp = `${d.toISOString().split('T')[0]}T${d.toTimeString().split(' ')[0]}.000Z`
        await this.refresh()
      }
      if (this.isSubscribed()) {
        this.defaultTemplates = await this.$store.dispatch('inbox/getTemplates', { authorId: 'platform' })
        await this.$store.dispatch('auth/getUserOrganizations', { fields: ['templates'] })
        this.userTemplates = this.$uElements('templates')
      }
    } catch (e) { this.useLogger(e) }
  },
  methods: {
    async refresh () {
      await this.$store.dispatch('inbox/fetchInbox', {
        id: this.currentNaturalUser.id,
        withInterlocutors: true,
        withCompanyAssets: this.isApplicant() ?? undefined,
        withMessages: true,
        interlocutorId: this.conversation.interlocutorId
      })
    },
    optionsDate (date) {
      return new Date(date) >= manipulateTime({ unit: 'days', nb: -1 }) && new Date(date) <= manipulateTime({ unit: 'years', nb: 1 }) && ![0, 6].includes(new Date(date).getDay())
    },
    updateTemplate () {
      this.newMessage = this.buildTemplate(this.selectedTemplate.content ?? this.selectedTemplate.data.content, {
        applicant: this.usersById[this.conversation.interlocutorId] ?? this.applicant,
        company: this.userCompany,
        offer: this.$_.get(this.application, 'assetSnapshot', undefined),
        locations: this.userCompany.locations
      })
    },
    async sendMessage ({ isScheduled = false }) {
      try {
        if (this.newMessage === '' || !this.newMessage.length) return
        this.loading = true

        // Link transactions in attachments
        let attachments = this.$_.get(this.conversation, 'messages[0].attachments', [])
        const isV2Conv = ![this.talkAssets.choTopicId, this.talkAssets.userTopicId, this.talkAssets.administrativeTopicId].includes(this.conversation.topicId)
        if (isV2Conv) attachments.push({ type: 'transaction', id: this.conversation.topicId })
        attachments = this.$_.uniqBy(attachments, ['id'])

        await this.$store.dispatch('inbox/sendMessage', {
          attachments,
          topicId: this.conversation.topicId,
          content: this.newMessage,
          senderId: this.currentUser.id,
          conversationId: this.$_.get(this.conversation, 'conversationId', this.$_.get(this.conversation, 'convId', undefined)),
          receiverId: this.conversation.interlocutorId,
          metadata: isScheduled ? { scheduledToSend: true, scheduledTimestamp: this.scheduledTimestamp } : undefined
        })
        this.newMessage = ''
        this.$emit('refresh')
        this.notifySuccess('notification.message_sent_success')
      } catch (e) {
        this.useLogger(e)
      } finally {
        this.loading = false
      }
    },
    async deleteMessage (message) {
      try {
        if (!message) throw new Error()
        this.loading = true
        await this.$store.dispatch('inbox/deleteMessage', { message })
        this.$emit('refresh')
        this.notifySuccess('notification.message_delete_success')
      } catch (e) {
        this.useLogger(e)
      } finally {
        this.loading = false
      }
    },
    onPaste (evt) {
      // Let inputs do their thing, so we don't break pasting of links.
      if (evt.target.nodeName === 'INPUT') return
      let text, onPasteStripFormattingIEPaste
      evt.preventDefault()
      evt.stopPropagation()
      if (evt.originalEvent && evt.originalEvent.clipboardData.getData) {
        text = evt.originalEvent.clipboardData.getData('text/plain')
        this.$refs.editorRef.runCmd('insertText', text)
      } else if (evt.clipboardData && evt.clipboardData.getData) {
        text = evt.clipboardData.getData('text/plain')
        this.$refs.editorRef.runCmd('insertText', text)
      } else if (window.clipboardData && window.clipboardData.getData) {
        if (!onPasteStripFormattingIEPaste) {
          onPasteStripFormattingIEPaste = true
          this.$refs.editorRef.runCmd('ms-pasteTextOnly', text)
        }
        onPasteStripFormattingIEPaste = false
      }
    },
    scrollToBottom () {
      this.$nextTick(() => {
        const objDiv = document.getElementById('scrollAreaId')
        if (objDiv) {
          // Both cause not working each time
          objDiv.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
          objDiv.scrollTop = objDiv.scrollHeight
        }
      })
    },

    // Allow user to archive conversation, updating both sent and received messages in it
    async convArchive (conversation) {
      this.$q.dialog({
        title: this.$t('prompt.conversation_archive.title'),
        message: this.$t(
          'prompt.conversation_archive.message', {
            interlocutor: this.pickFirstKey(
              this.$_.get(conversation, 'interlocutor', null),
              ['displayName', 'firstname', 'lastname']
            )
          }
        ),
        cancel: { label: this.$t('prompt.cancel_button'), flat: true, size: 'md', color: 'dark' },
        ok: { label: this.$t('prompt.confirm_button'), color: 'warning' },
        html: true,
      }).onOk(async () => {
        try {
          await this.conversationArchive(conversation)
          await this.refresh()
          this.notifySuccess('prompt.conversation_archive.success')
        } catch (e) {
          console.log(e)
          this.notifyError('prompt.conversation_archive.error')
        }
      })
    },

    async convMarkUnread (conversation) {
      try {
        const conversationUpdated = await this.conversationMarkAsUnread(conversation)
        await this.$store.dispatch('kanban/updateItem', { type: 'conversations', conversation: conversationUpdated, convMarkRead: false })
        this.notifySuccess('prompt.conversation_mark_as_unread.success')
      } catch (e) {
        console.log(e)
        this.notifyError('prompt.conversation_mark_as_unread.error')
      }
    }
  },
}
</script>
