import { arrayIfFilter, remapToPaginated } from 'hc-core/composables/misc.js'

export default {
  data () {
    return {
      loading: false,
      hasFavoriteList: false,
    }
  },
  computed: {
    favoriteList () { return this.$uElements('applicantList').find(l => this.$_.get(l, 'metadata.isFavoriteList', false)) ?? null },
    cvthequeList () { return this.$uElements('applicantList').find(l => this.$_.get(l, 'metadata.isCVThequeList', false)) ?? null },

    tableProp () {
      return {
        filters: {
          buttons: arrayIfFilter([
            { label: this.$t('applicantList.create'), color: 'positive', icon: 'uil:plus-circle', fn: (i) => { this.createList() } },
            { label: this.$t('applicantList.create_fav'), color: 'warning', icon: 'uil:star', if: !this.hasFavoriteList, fn: (i) => { this.createFavoriteList() } }
          ])
        },
        columns: [
          { name: 'label', align: 'left', label: this.$t('applicantList.label') },
          { name: 'description', align: 'left', label: this.$t('applicantList.description') },
          { name: 'nbItems', align: 'left', label: this.$t('applicantList.nb_applicants') },
          { name: 'actions', align: 'right', label: this.$t('applicantList.actions') },
        ],
        defaultParams: {
          authorId: this.currentUser.id,
          type: 'applicantList',
          nbResultsPerPage: 50
        },
        refresh: async (params) => { return this.refresh(params) },
      }
    }
  },
  methods: {
    hasId (list, id) {
      // list = [usr_123, usr_234] || whole doc object
      if (!list || !id) return false
      const idsArray = Array.isArray(list)
        ? list
        : this.$_.get(list, 'data.listStructure', false)
          ? this.$_.flattenDeep(this.$_.get(list, 'data.listStructure', []).map(st => st.applicantsIds ?? []))
          : []
      return this.$_.some(idsArray, item => (this.$_.isString(item) ? item === id : item.id === id))
    },

    isFavorite (id) { return this.hasId(this.favoriteList, id) },
    isCVThequeUnlocked (id) { return this.hasId(this.cvthequeList, id) },
    isApplicantInList (applicantId, list) { return this.hasId(list, applicantId) },

    async refresh (params = {}) {
      try {
        if (this.loading) return
        this.loading = true
        const request = await this.$store.dispatch('auth/getUserOrganizations', { fields: 'applicantList' })
        const lists = this.$_.get(request, 'applicantList', [])
        this.hasFavoriteList = lists.find(list => this.$_.get(list, 'metadata.isFavoriteList', false))
        return remapToPaginated(lists)
      } catch (e) {
        this.useLogger(e)
      } finally {
        this.loading = false
      }
    },

    async createList () {
      try {
        this.$q.dialog({
          title: this.$t('applicantList.create_title'),
          message: this.$t('applicantList.create_message'),
          prompt: { model: '', isValid: val => val.length >= 5 },
          cancel: { label: this.$t('prompt.cancel_button'), flat: true },
          ok: { color: 'positive', label: this.$t('prompt.generic_add_button') },
        }).onOk(async (label) => {
          await this.$store.dispatch('document/create', {
            authorId: this.currentUser.id,
            label: `applicantList_${this.currentUser.id}_${String(new Date().valueOf()).slice(-3).replace(/-+/g, '-')}`,
            type: 'applicantList',
            data: {
              label,
              listStructure: [
                {
                  label: this.$t('applicantList.create_status_default'),
                  applicantsIds: []
                }
              ]
            }
          })
          if (this.$refs.ApplicantListGenericTable) await this.$refs.ApplicantListGenericTable.refresh()
          else { await this.$store.dispatch('auth/getUserOrganizations', { fields: 'applicantList' }) }
          this.notifySuccess()
        })
      } catch (e) {
        this.useLogger(e)
      }
    },

    async createFavoriteList (silent = false) {
      try {
        const list = await this.$store.dispatch('document/create', {
          authorId: this.currentUser.id,
          label: `applicantList_${this.currentUser.id}_${String(new Date().valueOf()).slice(-3).replace(/-+/g, '-')}`,
          type: 'applicantList',
          data: {
            label: this.$t('applicantList.create_fav_label'),
            description: this.$t('applicantList.create_fav_description'),
            listStructure: [
              {
                label: this.$t('applicantList.create_status_default'),
                applicantsIds: []
              }
            ]
          },
          metadata: {
            isFavoriteList: true
          }
        })
        if (this.$refs.ApplicantListGenericTable) await this.$refs.ApplicantListGenericTable.refresh()
        else { await this.$store.dispatch('auth/getUserOrganizations', { fields: 'applicantList' }) }
        if (!silent) this.notifySuccess('applicantList.create_success')
        return list
      } catch (e) {
        this.useLogger(e)
      }
    },

    async unlockCvthequeApplicant (applicantId) {
      try {
        const res = await this.$store.dispatch('user/applicantsUnlock', { id: applicantId, requesterId: this.currentUser.id })
        if (res && res.success) {
          await this.$store.dispatch('auth/getUserOrganizations', { fields: ['applicantList', 'cvthequeCredits', 'rootUser'] })
          this.notifySuccess('shop.cvtheque.notification_success')
        } else {
          if (res.statusCode === 422) this.notifyError('shop.cvtheque.not_enough_credits')
          else this.notifyError()
        }
      } catch (e) {
        this.useLogger(e)
      }
    },

    async toggleApplicantOfList (applicantId, listId = undefined) {
      // listId : undefined > add to favorite list / set > add to specified list
      const lists = this.$uElements('applicantList')
      const favList = lists.find(l => this.$_.get(l, 'metadata.isFavoriteList', false))
      let list = listId ? lists.find(l => l.id === listId) ?? favList : favList
      if (this.$_.get(list, 'metadata.unremovable', false)) return

      // If not list, cretae the favorite one since it doesn't exist for now
      if (!list) list = await this.createFavoriteList(true)
      const listStructure0 = this.$_.get(list, 'data.listStructure[0]', { label: this.$t('applicantList.create_status_default'), applicantsIds: [] })
      const index = this.$_.findIndex(listStructure0.applicantsIds, item => (this.$_.isString(item) ? item === applicantId : item.id === applicantId))
      if (index !== -1) listStructure0.applicantsIds.splice(index, 1)
      else listStructure0.applicantsIds.push({ id: applicantId, timestamp: new Date().toISOString() })

      if (list && listStructure0) {
        await this.updateList({
          id: list.id,
          attrs: this.$_.set({}, 'data.listStructure[0]', listStructure0)
        })
      }
    },

    async updateList (params) {
      try {
        await this.$store.dispatch('document/update', params)
        this.notifySuccess('applicantList.update_success')
        if (this.$refs.ApplicantListGenericTable) await this.$refs.ApplicantListGenericTable.refresh()
        else { await this.$store.dispatch('auth/getUserOrganizations', { fields: 'applicantList' }) }
      } catch (e) {
        this.useLogger(e)
      }
    },

    async deleteList (list) {
      try {
        if (this.$_.get(list, 'metadata.unremovable', false)) {
          this.notifyError('Cette liste n\'est pas supprimable.')
          return
        }

        this.$q.dialog({
          title: this.$t('applicantList.delete_title'),
          message: this.$t({ id: 'applicantList.delete_message' }, { label: list.data.label }),
          html: true,
          cancel: { label: this.$t('prompt.cancel_button'), size: 'md', color: 'primary' },
          ok: { label: this.$t('prompt.delete_button'), size: 'md', color: 'negative' },
        }).onOk(async () => {
          await this.$store.dispatch('document/remove', { id: list.id })
          this.notifySuccess('applicantList.delete_success')
          if (this.$refs.ApplicantListGenericTable) await this.$refs.ApplicantListGenericTable.refresh()
          else { await this.$store.dispatch('auth/getUserOrganizations', { fields: 'applicantList' }) }
        })
      } catch (e) {
        this.useLogger(e)
      }
    }
  }
}
