<template>
  <QCheckbox
    v-model="cptValue"
    :color="props.color"
    :disable="props.readonly ?? false"
    :label="props.label ? computedLabel(props.label) : undefined"
  >
    <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </QCheckbox>
</template>

<script setup>
import { computed } from 'vue'
import { computedLabel } from 'hc-core/composables/intl'

const props = defineProps({
  modelValue: {
    required: true
  },
  readonly: {
    type: Boolean,
    default: false
  },
  label: String,
  color: {
    type: String,
    default: 'primary'
  },
})
const emit = defineEmits(['update:modelValue'])
const cptValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})
</script>
