import { get, intersection, isEmpty, set } from 'lodash'
import stelace from 'hc-core/composables/stelace.js'
import { Cookies } from 'quasar'
import { jwtDecode } from 'jwt-decode'
import { useStore } from 'vuex'

// Use this synchronous function to know if the current user is authenticated without a network request, e.g. to display login modal for unauthenticated users when they arrive in the app
export function getCurrentUserId () {
  const { userId } = stelace.auth.info()
  return userId
}

export function shouldAuthenticateAsOrg (user, config) {
  const isUpToDate = new Date(user.createdDate) > new Date('2021-07-01') || (get(user, 'platformData._private.version', 2) >= 3)
  // get(config, 'stelace.instant.shouldAuthenticateAsOrg') || []
  return !!intersection(user.roles, ['client', 'member', 'editor', 'administrator']).length && isUpToDate
}

// ### NEW IMPLEMENTATION LOGIC
// Structure of merged SessionData :
// {
//   accessToken: { userId, roles, loggedAt, iat, exp }
//   tokenType: 'Bearer',
//   userId: 'usr_XXX',
//   organizationId: 'org_XXX',
//   refreshToken: string
// }

const COOKIE_NAME = 'happycab-session'

export function useSession (ssrContext = null) {
  // HELPERS
  const store = useStore()
  const isBrowser = () => { return typeof window === 'object' }
  const decodeJwtToken = (jwtToken) => { return jwtDecode(jwtToken) }
  const sessionDataBuilder = (...sources) => {
    return Object.assign({}, ...sources.map(source => source ?? {}))
  }

  const sync = (source = {}) => {
    // If identical, skip
    const cookie = getCookie()
    const stlLocalStorage = getStlLocalStorage()
    if (JSON.stringify(cookie) === JSON.stringify(stlLocalStorage)) return
    // Find the latest updated token and use it as source of value
    const cookieExpiration = cookie && !isEmpty(cookie) ? get(decodeJwtToken(cookie.accessToken), 'exp', 0) : 0
    const stlLocalStorageExpiration = stlLocalStorage && !isEmpty(stlLocalStorage) ? get(decodeJwtToken(stlLocalStorage.accessToken), 'exp', 0) : 0
    let sessionData
    if (cookieExpiration > stlLocalStorageExpiration) {
      sessionData = sessionDataBuilder(stlLocalStorage, cookie, source)
    } else if (stlLocalStorageExpiration >= cookieExpiration) {
      sessionData = sessionDataBuilder(cookie, stlLocalStorage, source)
    }
    if (sessionData) {
      setStlLocalStorage(sessionData)
      setCookie(sessionData)
    }
  }

  // COOKIES
  const cookiesWrapper = (ssrContext && process.env.SERVER && !isBrowser) ? Cookies.parseSSR(ssrContext) : Cookies
  const COOKIE_OPTIONS = {
    expires: 30,
    path: '/',
    sameSite: 'Lax',
    domain: process.env.HC_BASE_URL.includes('localhost') ? 'localhost' : 'happycab.fr'
  }
  const getCookie = (key = null) => {
    const cookie = cookiesWrapper.get(COOKIE_NAME)
    return key ? get(cookie, key, undefined) : cookie
  }
  const setCookie = (data) => {
    return cookiesWrapper.set(COOKIE_NAME, data, COOKIE_OPTIONS)
  }
  const removeCookie = () => {
    cookiesWrapper.remove(COOKIE_NAME, COOKIE_OPTIONS)
  }

  // STELACE
  const getStlLocalStorage = () => {
    // type StlLocalStorage
    return stelace.getTokenStore().getTokens()
  }
  const setStlLocalStorage = (data) => {
    // type StlLocalStorage
    return stelace.getTokenStore().setTokens(data)
  }
  const removeStlLocalStorage = () => {
    stelace.getTokenStore().removeTokens()
  }

  // MAIN METHODS
  const purge = () => {
    removeCookie()
    removeStlLocalStorage()
    stelace.setOrganizationId(null)
  }
  const setKey = (key, value) => {
    sync(set({}, key, value))
  }
  const getKey = (key = null) => {
    return getCookie(key)
  }

  // SESSION EXPIRATION
  // const handleSessionExpiration = async () => {
  //   const refreshToken = getKey('refreshToken')
  //   if (refreshToken) {
  //     try {
  //       const call = await stelace.forward.post('/auth/token', {
  //         refreshToken,
  //         grantType: 'refreshToken'
  //       })
  //       setKey('accessToken', call.accessToken)
  //     } catch (e) {
  //       purge()
  //       throw e
  //     }
  //   } else {
  //     purge()
  //     throw new Error
  //   }
  // }

  // Remove redundancy when doing work possessed by work
  const asyncWorkAsOrg = async ({ orgId, fn }) => {
    const currentUserId = getCurrentUserId() // Save original
    if (orgId && orgId.startsWith('org_')) await store.dispatch('auth/selectOrganization', { organizationId: orgId })
    const result = await fn() // Doing the dowk as fn
    if (currentUserId && currentUserId.startsWith('org_')) await store.dispatch('auth/selectOrganization', { organizationId: currentUserId })
    return result
}

  return {
    // Main
    sync,
    purge,
    setKey,
    getKey,

    // Side
    asyncWorkAsOrg,
  }
}
