import { ref } from 'vue'

export function useIntegrations () {
  const integrations = ref([
    {
      key: 'digitalRecruiters',
      label: 'DigitalRecruiters',
      description: 'Publiez automatiquement vos offres depuis DigitalRecruiters vers HappyCab et son réseau de multidiffusion, et centralisez vos candidatures sur DigitalRecruiters.',
      image: 'platform/jobboards/digitalrecruiters.png',
      connectFn: () => {
        console.log('DigitalRecruiters')
      },
    },
    {
      key: 'jobAffinity',
      label: 'JobAffinity',
      description: 'Publiez automatiquement vos offres depuis JobAffinity vers HappyCab et son réseau de multidiffusion, et centralisez vos candidatures sur JobAffinity.',
      image: 'platform/jobboards/jobaffinity.png',
      connectFn: () => {
        console.log('jobAffinity')
      },
    },
    {
      key: 'quarksUp',
      label: 'QuarksUp',
      description: 'Publiez automatiquement vos offres depuis QuarksUp vers HappyCab et son réseau de multidiffusion, et centralisez vos candidatures sur QuarksUp.',
      image: 'platform/jobboards/quarksup.png',
      connectFn: () => {
        console.log('quarksUp')
      },
    },
    {
      key: 'teamTailor',
      label: 'TeamTailor',
      description: 'Publiez automatiquement vos offres depuis TeamTailor vers HappyCab et son réseau de multidiffusion, et centralisez vos candidatures sur TeamTailor.',
      image: 'platform/jobboards/teamtailor.png',
      connectFn: () => {
        console.log('teamTailor')
      },
    }
  ])

  return {
    integrations
  }
}
