<template>
  <router-view />
</template>
<script>
import { defineComponent } from 'vue'
import bootMixins from 'mixins/boot'

import { $t } from 'hc-core/composables/intl'
import stelace from 'hc-core/composables/stelace'
import { useSession } from 'hc-core/composables/useSession'

export default defineComponent({
  name: 'App',
  mixins: [bootMixins],
  async preFetch ({ store }) {
    await store.dispatch('common/initApp')
  },
  created () {
    this.$store.dispatch('common/initApp')
    this.$q.iconMapFn = (iconName) => {
      if (iconName.startsWith('uil:') === true) return { cls: `uil uil-${iconName.substring(4)}` }
    }
  },
  mounted () {
    this.setExpirationHandling()
  },
  methods: {
    setExpirationHandling () {
      const session = useSession()
      stelace.onError('userSessionExpired', async () => {
        session.purge()
        this.notifyError(
          'error.user_session_expired',
          {
            timeout: 0,
            closeBtn: null,
            actions: [
              {
                label: $t('authentication.log_in_button'),
                color: 'black',
                handler: () => {
                  window.open(
                    this.$app({ name: 'login' }),
                    '_self'
                  )
                }
              }
            ]
          }
        )
      })
    }
  }
})
</script>
