<template>
  <QCard
    v-if="article && article.fields"
    ref="ArticleCard"
    flat
    class="hc-link br-25 hc-shadow cursor-pointer bd-1 bd-grey-3"
    @click="articleRoute()"
  >
    <img
      :src="cdnImg($_.get(article, 'fields.cover', $t('images/placeholder')))"
      :alt="article ? article.name : ''"
      loading="lazy"
    >
    <QCardSection class="bd-1-top bd-grey-3">
      <div class="flex column q-gutter-y-sm">
        <!-- Title and chips -->
        <QItemLabel
          class="text-subtitle1 text-grey-7"
          lines="2"
        >
          {{ article.fields.title }}
        </QItemLabel>
        <div class="flex items-center justify-start">
          <QChip
            v-for="tag in article.metadata.categories"
            :key="tag"
            :label="$t({id:`pages.blog.categories.${tag}`})"
            class="hc-link text-black text-caption q-mr-md bg-blue-3 q-ml-none"
            @click="tagRoute(tag)"
          />
        </div>
        <div v-if="!article.metadata.categories">
          <QChip
            :label="$t('pages.blog.categories.no')"
            class="text-white text-caption bg-purple-3"
          />
        </div>
        <QItemLabel
          class="text-body2 text-grey-5"
          lines="3"
        >
          {{ article.fields.subtitle }}
        </QItemLabel>

        <div class="flex justify-between items-center text-caption text-grey-5 no-wrap">
          <div
            v-if="$_.get(article, 'fields.author', false)"
            class="flex items-center"
          >
            <QAvatar
              class="gt-md q-mr-md"
              size="36px"
            >
              <img
                :src="cdnImg(article.fields.author.avatar, { width: 36, height: 36, quality: 70 })"
                loading="lazy"
                alt="i"
                width="36"
                height="36"
              >
            </QAvatar>
            <div>
              <AppContent
                v-if="$_.get(article, 'fields.author', false)"
                path="pages.blog.by"
                :options="{ author: article.fields.author.name }"
                class="text-body2-bold text-grey-7"
              />
              <AppContent
                path="pages.blog.published"
                :options="{ datetime: $t({ id: 'time.date' }, { fmtd: articleDate }) }"
                class="flex text-caption text-grey-5"
              />
            </div>
          </div>
          <HCButton
            v-else
            force-label-on-xs
            size="sm"
            class="q-py-none text-caption"
            icon="uil:schedule"
            color="grey-5"
            :label="$t({ id: 'time.date' }, { fmtd: articleDate })"
          />
          <div :class="$_.get(article, 'fields.author', false) ? 'flex column items-end' : 'flex items-center justify-between'">
            <HCButton
              force-label-on-xs
              size="sm"
              class="q-py-none text-caption"
              icon="uil:clock"
              color="grey-5"
              :label="$t({ id: 'pages.blog.read_duration' }, { duration: $_.get(article, 'fields.duration') || 2 })"
            />
            <HCButton
              force-label-on-xs
              size="sm"
              class="q-py-none text-caption"
              icon="uil:heart"
              color="negative"
              :label="String($_.get(article, 'metadata.likes') || 0)"
            />
          </div>
        </div>
      </div>
    </QCardSection>
  </QCard>
</template>

<script>
export default {
  props: {
    article: { // expecting to be null when loading to show skeleton
      required: true,
      type: Object,
      validator: a => typeof a === 'object',
    },
  },
  computed: {
    articleDate () { return this.$_.defaultTo(this.$_.get(this.article, 'publishedDate', null), this.$_.get(this.article, 'createdDate', null), new Date()) },
  },
  methods: {
    articleRoute () {
      const baseRoute = { name: 'article', params: { slug: this.article ? this.article.name : false } }
      if (['WEB', 'VDC'].includes(process.env.HC_PLATFORM)) {
        if (process.env.HC_PLATFORM === 'WEB') {
          if (this.article.collection === 'blog') return this.$router.push(baseRoute)
          else if (this.article.collection === 'blog-vdc') return window.open(this.$vdc(baseRoute), '_blank')
        }
        if (process.env.HC_PLATFORM === 'VDC') {
          if (this.article.collection === 'blog-vdc') return this.$router.push(baseRoute)
          else if (this.article.collection === 'blog') return window.open(this.$web(baseRoute), '_blank')
        }
        return this.$router.push(baseRoute)
      } else return window.open(this.$web(baseRoute), '_blank')
    },
    tagRoute (tag) {
      const baseRoute = { name: 'blog', query: { category: tag } }
      if (['WEB', 'VDC'].includes(process.env.HC_PLATFORM)) return this.$router.push(baseRoute)
      else return this.$web(baseRoute)
    }
  }
}
</script>
