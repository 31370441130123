<template>
  <div class="full-width">
    <QInput
      ref="AutocompleteInput"
      v-model="result.label"
      class="full-width"
      :label="$t({ id: step.label })"
      :input-class="readonly ? 'text-grey-4' : ''"
      :placeholder="$t('prompt.placeholder_type')"
      :readonly="readonly"
      clearable
      debounce="1000"
      outlined
      :loading="loading"
      :dense="dense"
      @clear="result = { label: undefined, value: undefined };"
      @update:model-value="loading = true; fetchSuggestions(); stepResult();"
    />
    <template v-if="menuModel">
      <QMenu
        v-model="menuModel"
        fit
        max-height="50vh"
      >
        <QCard>
          <QList
            v-if="suggestions.length"
            dense
          >
            <template
              v-for="suggestion of suggestions"
              :key="suggestion.value"
            >
              <!-- Handle multiple alternative labels -->
              <template v-if="Array.isArray($_.get(suggestion, labelKey, false))">
                <QItem
                  v-for="(alt, iAlt) of $_.get(suggestion, labelKey, false)"
                  :key="iAlt"
                  class="cursor-pointer"
                  :clickable="!readonly"
                  @click="selectSuggestion(suggestion, iAlt)"
                >
                  {{ alt }}
                </QItem>
              </template>
              <QItem
                v-else
                class="cursor-pointer"
                :clickable="!readonly"
                @click="selectSuggestion(suggestion)"
              >
                {{ $_.get(suggestion, labelKey, 'Option') }}
              </QItem>
            </template>
          </QList>
          <div
            v-else
            class="full-width row flex-center text-negative q-gutter-sm"
          >
            <QIcon
              size="2em"
              name="uil:confused"
            />
            <AppContent path="error.no_data" />
          </div>
        </QCard>
      </QMenu>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
      // { label, field?, optional?, dataLabel, optionLabel?, formLabelField?, formValueField? }
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false
    },
  },
  emits: ['stepResult'],
  data () {
    return {
      loading: false,
      menuModel: false,
      suggestions: [],
      result: {
        label: undefined,
        value: undefined
      },
    }
  },
  computed: {
    labelKey () { return this.$_.get(this.step, 'optionLabel', 'label') }
  },
  created () {
    this.$nextTick(() => {
      if (this.step.field) {
        this.result.value = this.$_.get(this.formObject, this.step.field, undefined)
      } else {
        this.result.label = this.$_.get(this.formObject, this.step.formLabelField, undefined)
        this.result.value = this.$_.get(this.formObject, this.step.formValueField, undefined)
      }
      this.stepResult()
    })
  },
  mounted () {
    if (!this.step.light) this.$refs.AutocompleteInput.focus()
  },
  methods: {
    async fetchSuggestions () {
      this.loading = true
      this.suggestions = await this.$store.dispatch('content/getDataLabelOptions', {
        query: !this.$_.isNil(this.result.model) && this.result.model.length ? this.result.model : undefined,
        label: this.step.dataLabel,
        key: this.step.dataKey ?? undefined
      })
      this.menuModel = true
      this.loading = false
      this.$refs.AutocompleteInput.focus()
    },
    selectSuggestion (suggestion, labelIndex = 0) {
      this.result = {
        value: suggestion.value ?? undefined,
        label: Array.isArray(this.$_.get(suggestion, this.labelKey, false)) ? this.$_.get(suggestion, this.labelKey, ['Option'])[labelIndex ?? 0] : this.$_.get(suggestion, this.labelKey, 'Option')
      }

      this.menuModel = false
      this.loading = false
      this.stepResult()
    },
    async stepResult () {
      let canGoNextTmp = false
      const value = {}
      if (this.step.field) {
        this.$_.set(value, this.step.field, this.result.value)
        canGoNextTmp = !!this.result.value
      } else {
        this.$_.set(value, this.step.formLabelField, this.result.label)
        this.$_.set(value, this.step.formValueField, this.result.value ?? undefined)
        canGoNextTmp = this.$_.get(this.result, 'label.length', 0) > 0
      }

      this.$emit('stepResult', {
        canGoNext: canGoNextTmp || this.$_.get(this.step, 'optional', false),
        value
      })
    }
  }
}
</script>
