
import { omit } from 'lodash'
import { Notify } from 'quasar'
import { computedLabel } from 'hc-core/composables/intl'

export function useNotify () {
   // Notify
   const notify = (type = 'info', message, options = {}) => {
    const notificationType = {
      info: {
        icon: 'uil:info',
        classes: 'bg-info-light bd-1 bd-grey-3',
        iconColor: 'info',
        badgeColor: 'info',
      },
      error: {
        icon: 'uil:exclamation-octagon',
        classes: 'bg-negative-light bd-1 bd-grey-3',
        iconColor: 'negative',
        badgeColor: 'negative',
        message: 'notification.error',
      },
      success: {
        icon: 'uil:check',
        classes: 'bg-positive-light bd-1 bd-grey-3',
        iconColor: 'positive',
        badgeColor: 'positive',
        message: 'notification.saved',
      },
      warning: {
        icon: 'uil:exclamation-triangle',
        classes: 'bg-warning-light bd-1 bd-grey-3',
        iconColor: 'warning',
        badgeColor: 'warning',
      },
    }

    return Notify.create({
      textColor: options.textColor || 'black',
      position: 'bottom',
      timeout: 4000,
      progress: true,
      closeBtn: 'x',
      ...omit(notificationType[type], ['message']),
      ...options,
      message: computedLabel(message ?? notificationType[type].message ?? 'notification.saved'),
    })
  }

  // Predefined types
  const notifyInfo = (message, options) =>notify('info', message, options)
  const notifyError = (message, options) => notify('error', message, options)
  const notifySuccess = (message, options) => notify('success', message, options)
  const notifyWarning = (message, options) => notify('warning', message, options)

  // Complex progression updatable / heavy work notification
  const notifyInProgress = async ({
    message,
    options,
    items,
    fn,
    successMessage
  }) => {
    const notification = notify(
      'info',
      message,
      {
        ...options, // Should require actions
        group: false, // required to be updatable
        timeout: 0, // we want to be in control when it gets dismissed
        spinner: true,
        caption: `0 / ${items.length}`,
      }
    )

    const doneItems = []
    for (let index = 0; index < items.length; index++) {
      const item = items[index]
      doneItems.push(await fn(item))
      notification({ caption: `${index + 1} / ${items.length}` })
    }

    if (successMessage) notifySuccess(successMessage)

    notification() // Closing notification

    return doneItems
  }

  return {
    notify,
    notifyInfo,
    notifyError,
    notifySuccess,
    notifyWarning,
    notifyInProgress,
  }
}
