<template>
  <!-- eslint-disable vue/no-v-html -->
  <component
    :is="tag"
    v-if="renderAsHTML"
    ref="AppContent"
    class="stl-content-entry"
    v-bind="$attrs"
  >
    <span v-html="value" />
  </component>
  <!-- eslint-enable vue/no-v-html -->
  <component
    :is="tag"
    v-else-if="tag === 'q-btn'"
    ref="AppContent"
    class="stl-content-entry"
    :label="value"
    v-bind="$attrs"
  />
  <component
    :is="tag"
    v-else
    ref="AppContent"
    class="stl-content-entry"
    v-bind="$attrs"
  >
    {{ value }}
  </component>
</template>

<script>
export default {
  props: {
    tag: { // forced to 'div' when rendering HTML
      type: String,
      default: 'div'
    },
    options: {
      type: Object,
      default: () => ({})
    },
    path: {
      type: String,
      required: true,
    },
  },
  computed: {
    value () { return this.$t(this.path, this.options) },
    getContentTransformType2 () {
      const i18nContent = this.$store.getters['content/i18nContent']
      if (!i18nContent || !i18nContent.TRANSFORMED_KEYS) return false
      return i18nContent.TRANSFORMED_KEYS[`${this.path}`] || false
    },
    renderAsHTML () {
      const isHTML = /<\/?[a-z][\s\S]*>/i.test(this.value)
      return this.getContentTransformType2 === 'markdown' || isHTML
    },
  },
}
</script>

<style lang="sass">
// rendered markdown is wrapped in p tag
.stl-content-entry p
  margin-bottom: 0

.stl-content-entry a
  color: inherit
  text-decoration: none
  &:focus, &:hover
    text-decoration: underline
</style>
