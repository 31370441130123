<template>
  <div class="flex column items-start">
    <div>
      <!-- Hint -->
       <AppContent
        v-if="props.hint"
        :path="props.hint"
        class="text-caption text-grey-5 q-mb-md"
      />
      <!-- Date -->
      <div
        v-if="props.useDate"
        class="flex items-center q-gutter-x-sm no-wrap"
      >
        <QInput
          ref="timestampInputDateDayRef"
          v-model="timestampInputDateDay"
          mask="##"
          fill-mask
          unmasked-value
          outlined
          debounce="150"
          :readonly="props.readonly"
          style="width:60px; font-size: 25px;"
          @update:model-value="transferFocus(timestampInputDateDay, timestampInputDateMonthRef, 2)"
          @click="focusAndSelect(timestampInputDateDayRef)"
        />
        <div class="text-h5 q-ml-sm q-mr-xs">
          /
        </div>
        <QInput
          ref="timestampInputDateMonthRef"
          v-model="timestampInputDateMonth"
          mask="##"
          fill-mask
          unmasked-value
          outlined
          debounce="150"
          :readonly="props.readonly"
          style="width:60px; font-size: 25px;"
          @update:model-value="transferFocus(timestampInputDateMonth, timestampInputDateYearRef, 2)"
          @click="focusAndSelect(timestampInputDateMonthRef)"
        />
        <div class="text-h5 q-ml-sm q-mr-xs">
          /
        </div>
        <QInput
          ref="timestampInputDateYearRef"
          v-model="timestampInputDateYear"
          mask="####"
          fill-mask
          unmasked-value
          outlined
          debounce="150"
          :readonly="props.readonly"
          style="width:95px; font-size: 25px;"
          @click="focusAndSelect(timestampInputDateYearRef)"
        />
        <div>
          <QBtn
            icon="uil:calender"
            round
            color="primary"
          >
            <QPopupProxy
              ref="dateProxyRef"
              cover
              transition-show="scale"
              transition-hide="scale"
              @before-show="proxyDate = dateModel"
            >
              <QDate
                v-model="proxyDate"
                :options="props.dateOptions ?? undefined"
                @update:model-value="handleProxy"
              />
            </QPopupProxy>
          </QBtn>
        </div>
      </div>
      <div v-if="props.useDate && dateErrors.length" class="text-negative">
        <span
          v-for="(error, iError) of dateErrors"
          :key="iError"
        >
          {{ error }}
        </span>
      </div>
      <div v-if="props.useTime" class="text-h6 q-my-md">
        à
      </div>
      <!-- Time -->
      <div v-if="props.useTime" class="flex items-center q-gutter-x-sm no-wrap">
        <QInput
          ref="timestampInputTime1Ref"
          v-model="timestampInputTime1"
          mask="#"
          fill-mask
          unmasked-value
          outlined
          debounce="150"
          :readonly="props.readonly"
          style="width:42px; font-size: 25px;"
          @update:model-value="transferFocus(timestampInputTime1, timestampInputTime2Ref)"
          @click="focusAndSelect(timestampInputTime1Ref)"
        />
        <QInput
          ref="timestampInputTime2Ref"
          v-model="timestampInputTime2"
          mask="#"
          fill-mask
          unmasked-value
          outlined
          debounce="150"
          :readonly="props.readonly"
          style="width:42px; font-size: 25px;"
          @update:model-value="transferFocus(timestampInputTime2, timestampInputTime3Ref)"
          @click="focusAndSelect(timestampInputTime2Ref)"
        />
        <div class="text-h3 q-ml-sm q-mr-xs">
          :
        </div>
        <QInput
          ref="timestampInputTime3Ref"
          v-model="timestampInputTime3"
          mask="#"
          fill-mask
          unmasked-value
          outlined
          debounce="150"
          :readonly="props.readonly"
          style="width:42px; font-size: 25px;"
          @update:model-value="transferFocus(timestampInputTime3, timestampInputTime4Ref)"
          @click="focusAndSelect(timestampInputTime3Ref)"
        />
        <QInput
          ref="timestampInputTime4Ref"
          v-model="timestampInputTime4"
          mask="#"
          fill-mask
          unmasked-value
          outlined
          debounce="150"
          :readonly="props.readonly"
          style="width:42px; font-size: 25px;"
          @click="focusAndSelect(timestampInputTime4Ref)"
        />
      </div>
      <div v-if="props.useTime && timeErrors.length" class="text-negative">
        <span
          v-for="(error, iError) of timeErrors"
          :key="iError"
        >
          {{ error }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { $t } from 'hc-core/composables/intl'
import { computed, onMounted, ref, watch } from 'vue'
import { patterns } from 'quasar'
const { testPattern } = patterns

const props = defineProps({
  modelValue: { // Will be a ISO date all the time
    required: true,
    type: String,
    default: new Date().toISOString()
  },
  hint: {
    type: String,
    default: null
  },
  readonly: {
    type: Boolean,
    default: false
  },
  useDate: {
    type: Boolean,
    default: true
  },
  useTime: {
    type: Boolean,
    default: true
  },
  dateOptions: {
    type: Function,
    default: null
  },
  dateRules: {
    type: Array,
    default: () => []
  },
  timeRules: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['update:modelValue', 'validation'])

// Date
const timestampInputDateDayRef = ref(null)
const timestampInputDateMonthRef = ref(null)
const timestampInputDateYearRef = ref(null)
const timestampInputDateDay = ref(null)
const timestampInputDateMonth = ref(null)
const timestampInputDateYear = ref(null)
const dateModel = computed(() => {
  return `${timestampInputDateYear.value}-${timestampInputDateMonth.value}-${timestampInputDateDay.value}`
})
const dateErrors = computed(() => {
  const checks = [
    (
      new Date(dateModel.value) instanceof Date
      && (timestampInputDateDay.value >= 1 && timestampInputDateDay.value <= 31)
      && (timestampInputDateMonth.value >= 1 && timestampInputDateMonth.value <= 12)
      && (timestampInputDateYear.value >= 1900 && timestampInputDateYear.value <= 2100)
    ) || $t('ui.datetime.error.incorrect_date_input'),
    ...props.dateRules.map(rule => rule(dateModel.value)),
  ]
  const allTrue = checks.every(check => check === true)
  return allTrue ? [] : checks.filter(check => check !== true)
})

const dateProxyRef = ref(null)
const proxyDate = ref(null)
function handleProxy (event) {
  const splitten = event.split('/')
  timestampInputDateDay.value = splitten[2]
  timestampInputDateMonth.value = splitten[1]
  timestampInputDateYear.value = splitten[0]
  if (dateProxyRef.value) dateProxyRef.value.hide()
}

// Time
const timestampInputTime1Ref = ref(null)
const timestampInputTime2Ref = ref(null)
const timestampInputTime3Ref = ref(null)
const timestampInputTime4Ref = ref(null)
const timestampInputTime1 = ref(null)
const timestampInputTime2 = ref(null)
const timestampInputTime3 = ref(null)
const timestampInputTime4 = ref(null)
const timeErrors = computed(() => {
  const checks = [
    testPattern.time(timeModel.value) || $t('ui.datetime.error.incorrect_time_input'),
    ...props.timeRules.map(rule => rule(timeModel.value)),
  ]
  const allTrue = checks.every(check => check === true)
  return allTrue ? [] : checks.filter(check => check !== true)
})
const timeModel = computed(() => {
  return `${timestampInputTime1.value}${timestampInputTime2.value}:${timestampInputTime3.value}${timestampInputTime4.value}`
})

function transferFocus(value, focusTarget, lengthTarget = 1) {
  if (!Number.isNaN(Number.parseInt(value)) && String(value).length === lengthTarget) focusAndSelect(focusTarget)
}
function focusAndSelect(focusTarget) {
  if (focusTarget && focusTarget.focus && !props.readonly) {
    focusTarget.focus()
    const inputEl = focusTarget.$el.querySelector('input')
    inputEl.setSelectionRange(0, inputEl.value.length) // Set cursor to start and select full content
  }
}

// DATETIME
const dateTimeModel = computed (() => {
  return `${dateModel.value}T${timeModel.value}:00.000Z`
})
watch(dateTimeModel, (newValue) => {
  if (newValue) {
    emit('update:model-value', newValue)
    emit('validation', dateErrors.value.length === 0 && timeErrors.value.length === 0)
  }
})

onMounted(() => {
  timestampInputDateDay.value = props.modelValue.slice(8, 10)
  timestampInputDateMonth.value = props.modelValue.slice(5, 7)
  timestampInputDateYear.value = props.modelValue.slice(0, 4)

  const extractTime = props.modelValue.slice(11, 16)
  timestampInputTime1.value = extractTime[0]
  timestampInputTime2.value = extractTime[1]
  timestampInputTime3.value = extractTime[3]
  timestampInputTime4.value = extractTime[4]
})
</script>
