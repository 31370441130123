import script from "./datetime.vue?vue&type=script&setup=true&lang=js"
export * from "./datetime.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QBtn,QPopupProxy,QDate});
