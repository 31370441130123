export function reset (state) {
  const defaultState = {
    // Data
    element: null,
    applications: [],
    applicants: [],
    profileAssets: [],
    conversations: [],

    // Kanban
    stages: [],
    embed: false,

    orderByField: 'createdDate',
    orderByOrder: 'desc',
    filterUnread: [],
    stageModel: null,
    activeModel: true,
  }
  Object.keys(defaultState).forEach(key => { state[key] = defaultState[key] })
}

export function setKanbanData (state, commit) {
  Object.keys(commit).forEach(key => { state[key] = commit[key] })
}

export function replaceKanbanArrayData (state, { key, value, matchKey }) {
  // By providing an updated object, replace it in the correct array, enabling a smooth replace
  const indexOf = state[key].findIndex(item => item[matchKey ?? 'id'] === value[matchKey ?? 'id'])
  if (indexOf !== -1) state[key][indexOf] = value
}
