import { get } from 'lodash'
import { useSession } from 'hc-core/composables/useSession'

const session = useSession()

export function choUserId (state, getters, rootState) {
  return get(rootState, 'common.config.custom.users.cho')
}

// ###################### TRANSPOSE ####################
function hasRole (user, role) {
  if (user && role && user.roles && user.roles.includes(role)) return true
  else return false
}

// Only in organization context
export function isSubscribedOrganization (state, getters, rootState, rootGetters) {
  return rootGetters['auth/userOrganizations'].subscription ?? false
}

export function isEditorInOrg (state, getters, rootState, rootGetters) {
  const orgId = session.getKey('organizationId')
  return orgId &&
    rootGetters['auth/currentNaturalUser'].organizations &&
    hasRole(rootGetters['auth/currentNaturalUser'].organizations[orgId], 'editor')
}

export function isAdministratorInOrg (state, getters, rootState, rootGetters) {
  const orgId = session.getKey('organizationId')
  return (rootGetters['user/isOrganization'] && orgId &&
  rootGetters['auth/currentNaturalUser'].organizations &&
  hasRole(rootGetters['auth/currentNaturalUser'].organizations[orgId], 'administrator')) ||
  (!rootGetters['user/isOrganization'] && (hasRole(rootGetters['auth/currentNaturalUser'], 'phenix') || hasRole(rootGetters['auth/currentNaturalUser'], 'dragon')))
}

export function isEditorOrAbove (state, getters, rootState, rootGetters) {
  return rootGetters['user/isEditorInOrg'] || rootGetters['user/isAdministratorInOrg'] || rootGetters['user/isOrgRootOwner']
}

export function isOrgRootOwner (state, getters, rootState, rootGetters) {
  return get(rootGetters, '[`auth/currentNaturalUser`].id') === get(rootGetters, '[`auth/currentUser`].orgOwnerId')
}

// General roles
export function isApplicant (state, getters, rootState, rootGetters) {
  return hasRole(rootGetters['auth/currentNaturalUser'], 'applicant')
}

export function isOrganization (state, getters, rootState, rootGetters) {
  return hasRole(rootGetters['auth/currentUser'], 'organization')
}

export function isClient (state, getters, rootState, rootGetters) {
  return hasRole(rootGetters['auth/currentNaturalUser'], 'client') || rootGetters['user/isSubscribedOrganization']
}

export function isDragon (state, getters, rootState, rootGetters) {
  return hasRole(rootGetters['auth/currentNaturalUser'], 'dragon')
}

// export function hasPlanSlots (state, getters, rootState, rootGetters) {
//   const slots = rootGetters['auth/currentUser'].slots || 0
//   const implantations = rootGetters['auth/userOrganizations'].companies.length
//   return implantations < slots
// }

export function isPhenix (state, getters, rootState, rootGetters) {
  return hasRole(rootGetters['auth/currentNaturalUser'], 'phenix')
}

export function isSubscribed (state, getters, rootState, rootGetters) {
  if (rootGetters['user/isOrganization']) return rootGetters['user/isSubscribedOrganization']
  else {
    if (hasRole(rootGetters['auth/currentNaturalUser'], 'dragon')) return 'dragon'
    else if (hasRole(rootGetters['auth/currentNaturalUser'], 'phenix')) return 'phenix'
    else return false
  }
}
