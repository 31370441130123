<template>
  <div>
    <div id="EmojiPickerElement" ref="EmojiPickerElement" />
    <QCardSection>
      <AppContent path="pages.advantages.description" />
      <div class="flex justify-between items-center q-mt-md">
        <HCBigButton :disable="!isSubscribed() || !isEditorOrAboveInOrg()" :class="{'bg-grey-4 bd-2 bd-grey': !isSubscribed(), 'bg-positive text-white': isSubscribed()}" label="pages.advantages.advantage_add" plan-restricted="dragon" @click="advantageDialog = true" />
        <div v-if="isSubscribed() && isEditorOrAboveInOrg() && inputAdvantages.length > 0">
          <QBtn v-if="!orderMode" dense size="md" icon="uil:sort-amount-down" no-caps unelevated square :label="$t('pages.advantages.change_order')" @click="orderMode = true" />
          <QBtn v-if="orderMode" dense size="md" icon="uil:arrow-left" no-caps unelevated square :label="$t('prompt.leave_button')" @click="orderMode = false" />
        </div>
      </div>
      <div>
        <HCLoading v-if="loading" />
        <div v-else-if="inputAdvantages.length > 0">
          <QList v-if="!orderMode" flat bordered class="bg-grey-1 q-mt-md" separator>
            <QItem v-for="(advantage, index) of inputAdvantages" :key="'advantage_'+ index" clickable class="hc-advantage" @click.self="editAdvantage(index)">
              <QItemSection class="q-pl-sm q-pr-md" avatar @click="editAdvantage(index)">
                <span class="text-h6">
                  {{ hexToEmo(advantage.emoji) }}
                </span>
              </QItemSection>
              <QItemSection @click="editAdvantage(index)">
                <div class="text-subtitle1">
                  {{ advantage.text }}
                </div>
              </QItemSection>
              <QItemSection avatar>
                <div class="flex">
                  <QBtn class="hc-btn hoveredAction" flat dense unelevated :disable="!isEditorOrAboveInOrg()" @click="editAdvantage(index)">
                    <QIcon color="dark" name="uil:pen" />
                  </QBtn>
                  <QBtn class="hc-btn hoveredAction" flat dense unelevated :disable="!isEditorOrAboveInOrg()" @click="deleteAdvantage(index)">
                    <QIcon color="negative" name="uil:trash" />
                  </QBtn>
                </div>
              </QItemSection>
            </QItem>
            <QItem :disable="!isSubscribed() || !isEditorOrAboveInOrg()" clickable class="hc-advantage" @click="advantageDialog = true">
              <QItemSection class="flex flex-center">
                <div class="text-subtitle1">
                  <QIcon size="sm" name="uil:plus" />
                </div>
              </QItemSection>
            </QItem>
          </QList>

          <QList v-else flat bordered class="bg-grey-1 q-mt-md">
            <draggable v-model="inputAdvantages" item-key="key">
              <template #item="{element}">
                <QItem class="bd-1-bottom bd-grey" style="cursor: grab !important">
                  <QItemSection class="q-pl-sm q-pr-md" avatar>
                    <QIcon name="uil:arrows-v-alt" />
                  </QItemSection>
                  <QItemSection class="q-pl-sm q-pr-md" avatar>
                    <span class="text-h6">
                      {{ hexToEmo(element.emoji) }}
                    </span>
                  </QItemSection>
                  <QItemSection>
                    <div class="text-subtitle1">
                      {{ element.text }}
                    </div>
                  </QItemSection>
                </QItem>
              </template>
            </draggable>
            <QItem clickable @click="orderMode = false">
              <QItemSection>
                <HCButton icon="uil:times" label="prompt.close_button" />
              </QItemSection>
            </QItem>
          </QList>
        </div>
        <div v-else>
          <AppContent class="q-mt-md" path="pages.advantages.not_yet" />
        </div>
      </div>
    </QCardSection>

    <QDialog v-model="advantageDialog" :maximized="$q.screen.lt.md" persistent @hide="closeAdvantage">
      <AccountCard :class="$q.screen.lt.md ? 'flex column' : ''" :style="$q.screen.lt.md ? 'width:100%;': 'overflow:visible; width:100%; max-width:700px;'" :path="`pages.advantages.advantage_${editMode ? 'edit' : 'add'}`" :icon="editMode ? 'uil:pen' : 'uil:plus-circle'" style="min-width:50%; min-height:300px;">
        <template #header-right>
          <HCButton v-close-popup is-close @click="closeAdvantage" />
        </template>
        <QCardSection>
          <AppContent class="q-mt-md text-subtitle2" path="pages.advantages.advantage_description" />
          <div class="flex q-mt-md items-center no-wrap">
            <QBtn flat round size="22px" class="q-mr-sm" :label="hexToEmo(selectedAdvantage.emoji)" @click="setupEventListener">
              <ActionTooltip path="pages.advantages.choose_emoji" />
              <QPopupProxy>
                <emoji-picker
                  class="light"
                  locale="fr"
                  @emoji-click="onEmojiSelect"
                />
              </QPopupProxy>
            </QBtn>
            <QInput v-model="selectedAdvantage.text" style="flex-grow:100;" bg-color="white" :label="$t('pages.advantages.advantage_description_short')" outlined counter :maxlength="120" />
          </div>
          <AppContent v-if="displayError" class="q-mt-md text-negative" path="pages.advantages.error_msg" />
        </QCardSection>
        <QCardSection :style="$q.screen.lt.md ? 'flex:1; align-items: flex-end;' : ''" class="flex-center flex">
          <div class="flex flex-center">
            <HCBigButton color="positive" :label="`prompt.${editMode ? 'save_button' : 'generic_add_button'}`" @click="saveAdvantage" />
          </div>
        </QCardSection>
      </AccountCard>
    </QDialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { hexToEmo, emoToHex } from 'hc-core/composables/misc.js'

export default {
  components: { draggable },
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepResult'],
  data () {
    return {
      inputAdvantages: [],
      advantageDialog: false,
      selectedAdvantage: {
        text: '',
        emoji: '1f381'
      },

      editMode: false,
      editedIndex: null,
      displayError: false,
      orderMode: false,
    }
  },
  watch: {
    inputAdvantages: function (val) { this.stepResult() }
  },
  created () {
    this.$nextTick(() => {
      if (!this.$_.get(this.formObject, this.step.field) && this.$_.get(this.step, 'copyFromObject')) {
        this.inputAdvantages = this.$_.cloneDeep(this.$_.get(this.step, `copyFromObject.${this.step.field}`, []))
      } else this.inputAdvantages = this.$_.cloneDeep(this.$_.get(this.formObject, this.step.field, []))
      this.inputAdvantages = this.inputAdvantages ?? [] // Security so not using null values
      this.stepResult()
    })
  },
  mounted () {
    if (process.env.CLIENT) {
      this.$nextTick(() => {
        const scriptElement = document.createElement('script')
        scriptElement.setAttribute('src', 'https://cdn.jsdelivr.net/npm/emoji-picker-element@^1/index.js')
        scriptElement.setAttribute('type', 'module')
        document.getElementById('EmojiPickerElement').appendChild(scriptElement)
      })
    }
  },
  methods: {
    hexToEmo,
    stepResult () {
      const value = {}
      this.$_.set(value, this.step.field, this.inputAdvantages ?? [])
      this.$emit('stepResult', {
        canGoNext: this.inputAdvantages.length > 0 || this.$_.get(this.step, 'optional', false),
        value
      })
    },
    onEmojiSelect (event) { this.selectedAdvantage.emoji = emoToHex(this.$_.get(event, 'detail.unicode', '🎁')) },
    saveAdvantage () {
      this.displayError = false
      if (!this.selectedAdvantage.text.length || !this.selectedAdvantage.emoji.length) {
        this.displayError = true
        return
      }
      const advantages = this.$_.cloneDeep(this.inputAdvantages)
      if (this.editMode) {
        advantages[this.editedIndex] = this.selectedAdvantage
        this.editedIndex = null
      } else {
        advantages.push(this.selectedAdvantage)
      }
      this.inputAdvantages = advantages
      this.closeAdvantage()
    },
    editAdvantage (index) {
      if (!this.isEditorOrAboveInOrg()) return
      this.selectedAdvantage = this.inputAdvantages[index]
      this.editMode = true
      this.editedIndex = index
      this.advantageDialog = true
    },
    closeAdvantage () {
      this.advantageDialog = false
      this.selectedAdvantage = {
        text: '',
        emoji: '1f381'
      }
      this.editMode = false
      this.editedIndex = null
    },
    deleteAdvantage (index) {
      this.$q.dialog({
        title: this.$t('pages.advantages.delete_advantage'),
        message: this.$t('pages.advantages.delete_advantage_confirm'),
        cancel: {
          label: this.$t('prompt.cancel_button'),
          flat: true,
          noCaps: true,
          icon: 'uil:times'
        },
        ok: {
          color: 'negative',
          label: this.$t('prompt.delete_button'),
          flat: true,
          noCaps: true,
          icon: 'uil:trash'
        }
      }).onOk(() => {
        const advantages = this.$_.cloneDeep(this.inputAdvantages)
        advantages.splice(index, 1)
        this.inputAdvantages = advantages
      })
    },
  }
}
</script>
