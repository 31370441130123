<template>
  <div ref="OfferDate">
    <div :class="['flex q-gutter-md', {'no-wrap': $q.screen.gt.sm}]">
      <QChip
        v-for="chip in dateOptions"
        :key="chip.value"
        :label="chip.label"
        class="text-white br-8"
        :color="contractDate === chip.value ? 'secondary' : 'grey-4'"
        unelevated
        :clickable="!readonly"
        no-caps
        @click="contractDate = chip.value; stepResult()"
      />
    </div>
    <div v-if="contractDate === 'precise'" class="flex items-center q-gutter-md q-my-sm" @click="$refs.offerDateQDate.show()">
      <div class="cursor-pointer">
        <AppContent v-if="isValidDate(dateInput) && calendarOptions(dateInput)" path="time.date" :options="{ fmtd: dateInput }" />
        <AppContent v-else tag="span" path="component.forms.offerForm.contractDate.precise" />
      </div>
      <QBtn icon="event" round color="primary">
        <QPopupProxy ref="offerDateQDate" transition-show="scale" transition-hide="scale">
          <QDate
            v-model="dateInput"
            :title="dateInput ? $t({ id: 'time.date' }, { fmtd: dateInput }) : '-'"
            :subtitle="dateInput ? $t('component.forms.offerForm.contractDate.offerLabel') : 'Date'"
            :options="calendarOptions"
            :readonly="readonly"
            text-color="black"
            mask="YYYY-MM-DDT00:01:00.000Z"
            color="grey-4"
            bordered
            flat
            @update:model-value="stepResult(); $refs.offerDateQDate.hide()"
          />
        </QPopupProxy>
      </QBtn>
    </div>
  </div>
</template>

<script>
import { manipulateTime, getIso } from 'hc-core/composables/time.js'
import ValidationMixin from 'hc-core/mixins/validation'

export default {
  mixins: [ValidationMixin],
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepResult'],
  data () {
    return {
      dateOptions: [
        { label: this.$t('component.forms.offerForm.contractDate.notSpecified'), value: 'notSpecified' },
        { label: this.$t('component.forms.offerForm.contractDate.asap'), value: 'asap' },
        { label: this.$t('component.forms.offerForm.contractDate.precise'), value: 'precise' },
      ],
      contractDate: null,
      dateInput: null,
    }
  },
  created () {
    this.$nextTick(() => {
      if (this.$_.has(this.formObject, 'metadata._job.contractDate')) {
        if (this.isValidDate(this.formObject.metadata._job.contractDate)) {
          this.dateInput = getIso(this.formObject.metadata._job.contractDate)
          this.contractDate = 'precise'
        } else this.contractDate = this.formObject.metadata._job.contractDate
      }
      this.stepResult()
    })
  },
  methods: {
    calendarOptions (date) {
      return new Date(date) >= manipulateTime({ unit: 'hours', nb: 1 }) && new Date(date) <= manipulateTime({ unit: 'years', nb: 1 }) && ![0, 6].includes(new Date(date).getDay())
    },
    stepResult () {
      const hasFilledValidDate = this.dateInput && new Date(this.dateInput) && this.contractDate === 'precise'
      const canGoNext = (this.step && this.step.optional) || (
        (this.contractDate === 'notSpecified' || this.contractDate === 'asap') || hasFilledValidDate
      )
      let value = {}
      if (this.contractDate === 'notSpecified' || this.contractDate === 'asap') {
        value = { metadata: { _job: { contractDate: this.contractDate } } }
      } else if (hasFilledValidDate) {
        value = { metadata: { _job: { contractDate: getIso(this.dateInput) } } }
      }
      this.$emit('stepResult', { canGoNext, value })
    },
  },
}
</script>
