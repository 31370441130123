import { get, mergeWith, set } from 'lodash'
import { createIntl } from 'vue-intl'

let $t

export async function intlInit ({ app, store }) {
  // Fetch translations
  const locale = store.state.content.locale || process.env.VUE_APP_DEFAULT_LANGUAGE || 'fr'
  const currency = process.env.VUE_APP_DEFAULT_CURRENCY || 'eur'
  await store.dispatch('content/fetchi18nContent', { locale })

  // Add vue-intl to Vue instance
  app.use(createIntl({
    locale: locale,
    defaultLocale: locale,
    messages: store.getters['content/i18nContent'],
  }))

  // Prototypes
  $t = function (messageDescriptor, values = {}) {
    try {
      // TEMPO : allow simple string to convert later to only strings
      if (typeof messageDescriptor === 'string') {
        messageDescriptor = { id: messageDescriptor }
      }

      // 1. Check other formatting types first
      // Numeric types
      if (['number', 'currency', 'decimal', 'unit'].includes(get(values, 'formatType', false)) && get(values, 'input', false)) {
        if (get(values, 'roundingMode', false)) {
          switch (values.roundingMode) {
            case 'ceil': values.input = Math.ceil(values.input); break
            case 'floor': values.input = Math.floor(values.input); break
            case 'round': values.input = Math.round(values.input); break
            case 'trunc': values.input = Math.trunc(values.input); break
          }
        }
        return app.config.globalProperties.$formatNumber(
          values.input,
          {
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
            style: values.formatType,
            currency: values.currency ?? String(currency).toUpperCase(),
            currencyDisplay: values.currencyDisplay ?? 'symbol',
            roundingMode: values.roundingMode ?? 'halfExpand',
            unit: values.formatType === 'unit' && values.unit ? values.unit : 'month',
            unitDisplay: values.unitDisplay ?? 'long',
          }
        )
      }

      // Time : auto format params to Date instances (when asked with fmt prefix) so vue-intl handle it in formatMessage
      if (get(messageDescriptor, 'id', '').startsWith('time.') && Object.keys(values).length > 0) {
        for (const [key, value] of Object.entries(values)) {
          if (key.match('fmt')) set(values, key.replace('fmt', ''), (value instanceof Date) ? value : (typeof value === 'string') ? new Date(value) : value)
        }
      }

      // TODO : Other types to come, like date, time, relativeTime, ...

      // 2. Only left type is text
      // Ensure translation exists
      const translation = get(store.getters['content/i18nContent'], messageDescriptor.id, false)
      if (!translation) {
        if (process.env.NODE_ENV === 'production') return messageDescriptor.defaultMessage
        else return messageDescriptor.defaultMessage ?? messageDescriptor.id
      }

      // Replace fallback values in yaml strings
      let valueSearch
      const fallbackValues = { SERVICE_NAME: process.env.VUE_APP_SERVICE_NAME }
      const optionalValuesRegex = /{([^,{}]+),\s*select\s*,[^,]*(undefined\s*{|other\s*{})/g
      while ((valueSearch = optionalValuesRegex.exec(translation)) !== null) {
        fallbackValues[valueSearch[1].trim()] = undefined
      }
      values = mergeWith(
        {}, fallbackValues, values,
        (a, b) => b !== null ? b : a ?? undefined
      )

      return app.config.globalProperties.$formatMessage(messageDescriptor, values, {
        ignoreTag: true,
        br: chunks => <br />,
        p: chunks => <p>{chunks}</p>,
        b: chunks => <b>{chunks}</b>,
      })
    } catch (e) {
      console.log(e)
      return ''
    }
  }

  app.config.globalProperties.$t = $t
}

const computedLabel = (label) => {
  return label ? translationExists(label) ? $t(label) : label : false
}

// Copied from global mixins, need to remove
const translationExists = (string) => {
  const t = $t(string)
  return string && t && t !== string && t !== '' && t !== undefined
}

export {
  $t,
  computedLabel,
  translationExists
}
