<template>
  <AccountCard
    ref="AccountChoCard"
    :loading="!ready"
    icon="uil:comments"
    path="component.company.account.cho.title"
  >
    <QCardSection>
      <div class="flex justify-between q-mb-md">
        <AppContent
          class="text-subtitle2 text-grey"
          path="component.company.account.cho.subtitle"
          :options="{ choName: user.displayName }"
        />
      </div>
      <QSeparator />
      <div class="flex items-center q-mt-sm">
        <AppAvatar
          :entity="user"
          size="100px"
          class="gt-sm q-mr-md"
        />
        <div class="flex column items-start">
          <div class="gt-sm text-body1-bold q-mr-md">
            {{ user.displayName }}
          </div>
          <div
            v-if="$_.get(user, 'metadata._public.role')"
            class="gt-sm"
          >
            {{ user.metadata._public.role }}
          </div>
          <AppContent
            v-else
            class="gt-sm"
            path="component.company.account.cho.job"
          />
          <div class="lt-md flex items-center justify-start q-my-sm">
            <div class="text-body1-bold q-mr-md">
              {{ user.displayName }}
            </div>
            <div v-if="$_.get(user, 'metadata._public.role')">
              {{ user.metadata._public.role }}
            </div>
            <AppContent v-else path="component.company.account.cho.job" />
          </div>
          <HCButton
            v-if="$_.get(user, 'email', false)"
            force-label-on-xs
            :label="user.email"
            :href="`mailto:${user.email}`"
            icon="uil:envelope-alt"
          />
          <HCButton
            v-if="$_.get(user, 'metadata._private.phone', false)"
            force-label-on-xs
            :label="user.metadata._private.phone"
            :href="`tel:${user.metadata._private.phone}`"
            icon="uil:phone"
          />
        </div>
      </div>
    </QCardSection>
  </AccountCard>
</template>

<script>
export default {
  data () {
    return {
      user: {},
      text: '',
      ready: false,
    }
  },
  computed: {
    choId () { return this.$store.getters['user/choUserId'] }
  },
  watch: {
    choId: {
      immediate: true,
      handler: async function (id, oldId) {
        if (id) {
          this.user = await this.$store.dispatch('user/read', { id })
          this.$nextTick(() => {
            this.ready = true
          })
        }
      }
    }
  }
}
</script>
