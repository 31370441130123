<template>
  <div ref="ItemsArrayInput" class="full-width">
    <QList>
      <QExpansionItem v-for="(item, i) of arr" :key="i" group="itemsArrayInputgroup">
        <template #header>
          <div class="row full-width">
            <div class="full-width overflow-hidden" style="width:inherit; white-space:nowrap; text-overflow:ellipsis">
              <!-- {{ item.title }} -->
              {{ textDisplay(item, 'isTitle') ? textDisplay(item, 'isTitle') : $t('prompt.to_fill') }}
            </div>
            <div v-if="textDisplay(item, 'isSubtitle')" class="text-grey-5 text-caption">
              {{ textDisplay(item, 'isSubtitle') }}
              <!-- {{ $_.get(item, $_.get(step.arrayItemModel.find(k => $_.get(k, 'isSubtitle', false)), 'field', 'title'), false) }} -->
            </div>
          </div>
        </template>
        <div class="bg-grey-1 q-pa-sm br-8-bottom row full-width q-col-gutter-x-xs">
          <component
            :is="modelKey.component"
            v-for="(modelKey, j) of step.arrayItemModel"
            :key="j"
            :step="modelKey"
            :form-object="item"
            :entity="item"
            :readonly="readonly"
            light
            :class="$_.get(modelKey, 'class', undefined)"
            :style="$_.get(modelKey, 'style', undefined)"

            :value="$_.get(item, modelKey.field, null)"
            :placeholder="$_.get(modelKey, 'placeholder', false) ? $t({ id: modelKey.placeholder }) : undefined"

            @selectPlace="checkComponent({ canGoNext: $event !== null, value: $event }, modelKey, i)"
            @stepResult="checkComponent($event, modelKey, i)"
          />
          <!-- :field="step" -->
          <!-- :class="{'hc-focused': checkFocus('modelKey.focusId')}" -->
          <div class="col-12 q-mt-sm flex justify-end items-center">
            <HCButton tooltip="prompt.delete_button" label="prompt.delete_button" icon="uil:trash-alt" color="negative" @click="arr.splice(i, 1)" />
          </div>
        </div>
      </QExpansionItem>
      <div class="q-mt-md text-center">
        <HCBigButton :tooltip="step.addLabel" :label="step.addLabel" @click="arr.push({})" />
      </div>
    </QList>
  </div>
</template>

<script>
import ISOInput from 'hc-core/components/forms/iso-input'
import TextInput from 'hc-core/components/forms/text-input'
import EmojiInput from 'hc-core/components/forms/emoji-input'
import SelectInput from 'hc-core/components/forms/select-input'
import SliderInput from 'hc-core/components/forms/slider-input'
import PlacesAutocomplete from 'hc-core/components/inputs/places-autocomplete'

export default {
  components: {
    ISOInput,
    TextInput,
    EmojiInput,
    SelectInput,
    SliderInput,
    PlacesAutocomplete,
  },
  mixins: [],
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepResult'],
  data () {
    return {
      arr: []
    }
  },
  created () {
    this.$nextTick(() => {
      if (!this.$_.get(this.formObject, this.step.field) && this.$_.get(this.step, 'copyFromObject')) {
        this.arr = this.$_.get(this.step, `copyFromObject.${this.step.field}`)
      } else this.arr = this.$_.get(this.formObject, this.step.field, [])
      this.stepResult()
    })
  },
  methods: {
    checkFocus (item) { return item && this.$store.state.common.focused === item },
    checkComponent (evt, modelKey, i) {
      if (this.$_.get(evt, 'canGoNext', false)) {
        this.$_.set(this.arr, `[${i}].${modelKey.field}`, this.$_.get(evt.value, modelKey.field, undefined))
        this.stepResult()
      }
    },
    textDisplay (item, key = 'isTitle') {
      return this.$_.get(this.step, `${key}Fn`, false)
        ? this.step[`${key}Fn`](item)
        : this.$_.get(item, this.$_.get(this.step.arrayItemModel.find(k => this.$_.get(k, key, false)), 'field', false), false)
    },
    stepResult () {
      const value = {}
      this.$_.set(value, this.step.field, this.arr)
      this.$emit('stepResult', {
        canGoNext: this.$_.get(this.arr, 'length', 0) > 0 || this.$_.get(this.step, 'optional', false),
        value: value,
      })
    },
  }
}
</script>
