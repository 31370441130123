<template>
  <QDialog ref="MediaImageDialog" @hide="$emit('close')">
    <AccountCard path="component.dialogs.media_image_dialog.manage" :bordered="false" icon="uil:image-edit" style="max-height: 90vh; max-width: 90vw">
      <template #header-right>
        <HCButton v-close-popup is-close />
      </template>
      <div class="relative-position">
        <ImageCropperUploader
          v-if="showImageCropper"
          :field="field"
          :entity="userCompany"
          @close="showImageCropper = false; $emit('close')"
          @imageUploaded="uploadImage($event, field)"
        />
        <img v-else :src="getWebpImageUrl(dataValue.key)" loading="lazy" style="width: 100%; max-height: 60vh; object-fit: scale-down;">
        <QBtn
          v-if="!readonly && isEditorOrAboveInOrg()"
          icon="uil:crop-alt"
          :label="$t('component.dialogs.media_image_dialog.crop')"
          class="absolute absolute-bottom-left bg-secondary text-white text-caption q-ma-md"
          @click="showImageCropper = !showImageCropper"
        />
      </div>
      <QCardSection v-if="dataValue" class="items-center full-width">
        <QInput v-model="dataValue.caption" maxlength="150" counter clearable outlined :readonly="readonly || !isEditorOrAboveInOrg()" :label="$t('component.dialogs.media_image_dialog.legend')" debounce="500" @update:modelValue="askEmit('edit')" />
      </QCardSection>
      <QCardActions v-if="!readonly && isEditorOrAboveInOrg()" class="items-center justify-between">
        <HCBigButton
          label="prompt.delete_button"
          color="negative"
          @click="askEmit('delete'); $emit('close')"
        />
        <HCBigButton
          label="prompt.save_button"
          @click="$emit('close')"
        />
      </QCardActions>
    </AccountCard>
  </QDialog>
</template>

<script>
import AwsMixins from 'hc-core/mixins/aws.js'
import ImageCropperUploader from 'hc-core/components/files/image-cropper-uploader'

export default {
  components: { ImageCropperUploader },
  mixins: [AwsMixins],
  props: {
    field: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['updateEntity', 'close'],
  data () {
    return {
      dataValue: {
        key: 'platform/branding/placeholder.jpg',
        type: 'image',
        caption: '',
      },
      showImageCropper: false,
    }
  },
  mounted () {
    if (this.field.value) this.dataValue = this.field.value
    if (this.field.isNew) this.showImageCropper = true
    this.$refs.MediaImageDialog.show()
  },
  methods: {
    uploadImage (event, field) {
      this.$_.set(this.dataValue, 'key', this.getFileKey(event))
      this.askEmit('edit')
      this.$refs.MediaImageDialog.hide()
    },
    askEmit (method) {
      this.$emit('updateEntity', {
        method,
        field: this.field,
        value: this.dataValue,
        entity: this.userCompany,
      })
    }
  },
}
</script>
