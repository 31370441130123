Object.replaceAll = function (entity, needle, replacement, affectsKeys, affectsValues) {
  affectsKeys = typeof affectsKeys === 'undefined' ? true : affectsKeys
  affectsValues = typeof affectsValues === 'undefined' ? true : affectsValues

  const newEntity = {}
  const regExp = new RegExp(needle, 'g')
  for (const property in entity) {
    // eslint-disable-next-line
    if (!entity.hasOwnProperty(property)) {
      continue
    }
    let value = entity[property]
    let newProperty = property
    if (affectsKeys) {
      newProperty = property.replace(regExp, replacement)
    }
    if (affectsValues) {
      if (typeof value === 'object') {
        value = Object.replaceAll(value, needle, replacement, affectsKeys, affectsValues)
      } else if (typeof value === 'string') {
        value = value.replace(regExp, replacement)
      }
    }
    newEntity[newProperty] = value
  }
  return newEntity
}

export default {
  methods: {
    // Checkbox
    validField (val) { return val || this.$t('form.error.missing_field') },

    // Field validation : Check if field is not undefined
    requiredField (val) { return !!val || this.$t('form.error.missing_field') },
    requiredFieldEmpty (val) { return !!val || '' },

    // Field validation : Check if field correspond to email pattern
    isValidEmail (val) {
      const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{2,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/
      return emailPattern.test(val) // || this.$t('form.error.invalid_email')
    },

    isValidEmailOrEmpty (val) {
      if (val === '') return true
      const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{2,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/
      return emailPattern.test(val) || this.$t('form.error.invalid_email')
    },

    // Field validation : Check if field correspond to phone pattern
    isValidPhone (val) {
      const phonePatternTrim = /^[0-9]{10,10}$/
      const phonePatternSplit = /^(\s*\d{2}){5}$/
      return (phonePatternTrim.test(val) || phonePatternSplit.test(val)) || this.$t('form.error.invalid_phone')
    },

    isValidDate (date) {
      return !isNaN(new Date(date))
    },

    // Field validation : Check if field correspond to URL pattern
    isValidURL (str) {
      if (str === '' || str === undefined || str === null) return true
      const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
      return !!pattern.test(str) || this.$t('form.error.invalid_url')
    },

    // Time
    isFutureDate (date) { return date && new Date(date) > new Date() },

    // Field validation : Check if field has at least 8 characters
    atLeastEight (val) { return val.length >= 8 || 'Veuillez saisir au moins 8 caractères' },

    // Field validation : Check if email account is free
    isEmailAvailable (val) { return this.emailAvailable || this.$t('authentication.error.email_already_used') },

    // Field validation : Check if email account is free
    async checkEmailAvailable (val) {
      const available = await this.$store.dispatch('auth/checkAvailability', { username: val })
      return available || this.$t('authentication.error.email_already_used')
    },

    // Field validation : Check if email account is free
    isValidDateString (val) {
      try {
        // const date = new Date(val) // eslint-disable-line no-new
        // return !isNaN(date)
        const arr = val.split('/')
        return (arr.length === 3 && (arr[0] >= 1 && arr[0] <= 31) && (arr[1] >= 1 && arr[1] <= 12)) || 'Date incorrecte'
      } catch (e) {
        this.useLogger(e)
        return false
      }
    },
  }
}

// Field validation : Check if field correspond to phone pattern
export function usePhoneValidator (val) {
  const pattern = /^(\s*\d{2}){5}$/
  return {
    fn: pattern.test(val),
    errorLabel: 'form.error.invalid_phone'
  }
}

// Field validation : Check if field correspond to email pattern
export function useEmailValidator (val) {
  const pattern = /^(?=[a-zA-Z0-9@._%+-]{2,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/
  return {
    fn: pattern.test(val),
    errorLabel: 'form.error.invalid_email'
  }
}

// Field validation : Check if field correspond to url pattern
export function useUrlValidator (val) {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
  return {
    fn: (val === '' || val === undefined || val === null) || (val.length && pattern.test(val)),
    errorLabel: 'form.error.invalid_url'
  }
}
