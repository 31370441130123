<template>
  <component :is="integrated ? 'div' : 'AccountCard'" v-if="quest">
    <template #header-left>
      <QIcon size="26px" class="q-mr-sm text-primary" name="uil:file-question" />
      <QInput v-if="editMode" v-model="quest.data.label" label="Titre de votre questionnaire" :outlined="editMode" :borderless="!editMode" :readonly="!editMode" dense />
      <div v-else class="text-h6 text-weight-bold text-dark">
        {{ quest.data.label }}
      </div>
      <QSelect
        v-if="editMode"
        v-model="quest.label"
        label="Type de questionnaire"
        class="q-ml-sm"
        :options="questLabels.filter(({ type }) => type === 'form')"
        map-options
        emit-value
        outlined
        dense
      />
      <QBadge
        v-else
        class="q-ml-sm text-dark"
        :label="cptChip(quest).label"
        :color="cptChip(quest).color"
      />
    </template>
    <template #header-right>
      <QToggle
        v-if="!readonly"
        v-model="editMode"
        checked-icon="uil:pen"
        color="secondary"
        class="text-secondary"
        unchecked-icon="uil:eye"
        :label="editMode ? 'Édition' : 'Preview'"
      >
        <ActionTooltip :str="`Passer en ${editMode ? 'Preview' : 'Édition'}`" />
      </QToggle>
      <HCButton v-if="!readonly" class="q-ml-md" icon="uil:save" label="prompt.save_button" @click="triggerSave" />
    </template>
    <QCardSection v-if="integrated">
      <div class="flex justify-between">
        <div class="flex items-center">
          <QBadge
            class="q-ml-sm text-dark"
            :label="cptChip(quest).label"
            :color="cptChip(quest).color"
          />
          <HCButton v-if="quest.id && $route.name !== 'meetingForm' && cptQuestLabel(quest).openInMeeting" label="communication.forms.go_meeting" icon="uil:user-arrows" :to="{ name: 'meetingForm', params: { id: quest.id } }" class="q-ml-md" />
        </div>
        <div class="flex items-center">
          <QToggle
            v-if="!readonly"
            v-model="editMode"
            checked-icon="uil:pen"
            color="secondary"
            class="text-secondary"
            unchecked-icon="uil:eye"
            :label="editMode ? 'Édition' : 'Preview'"
          >
            <ActionTooltip :str="`Passer en ${editMode ? 'Preview' : 'Édition'}`" />
          </QToggle>
          <HCBigButton v-if="!readonly && $_.get(quest, 'metadata.isForm', false)" class="q-ml-md" icon="uil:save" color="positive" label="prompt.save_button" @click="triggerSave" />
        </div>
      </div>
    </QCardSection>
    <QCardSection>
      <div v-if="$_.get(quest.data.blocks, 'length', 0) === 0" class="row flex-center text-grey-5">
        Votre questionnaire est vide ! Ajouter des champs pour commencer à contruire votre questionnaire.
      </div>
      <draggable v-else v-model="quest.data.blocks" item-key="index" handle=".handle" class="row column" @change="draggableEvent">
        <template #item="{ element: block, index: blockIndex }">
          <div :key="blockIndex">
            <QSeparator v-if="blockIndex !== 0" class="q-my-sm full-width" />
            <div class="row flex justify-between items-center q-mb-sm q-gutter-x-sm">
              <div v-if="editMode" class="col-1 handle cursor-pointer" style="max-width: 30px">
                <QIcon name="uil:draggabledots" />
              </div>
              <div class="col">
                <QInput v-model="block.label" placeholder="Votre question" :outlined="editMode" :borderless="!editMode" :readonly="!editMode" :dense="editMode" autogrow>
                  <template #prepend>
                    <QIcon :name="questIcons[block.type]" color="secondary" class="q-mr-xs" />
                  </template>
                </QInput>
              </div>
              <div v-if="editMode" class="col-2 flex justify-end items-center no-wrap">
                <QToggle v-if="block.type === 'select'" v-model="block.multiple" label="Choix multiples" color="primary" dense />
                <QToggle v-if="block.type === 'text'" v-model="block.autogrow" label="Zone de texte" color="primary" dense />
                <HCButton icon="uil:times" color="negative" tooltip="Supprimer la question" @click="quest.data.blocks.splice(blockIndex, 1)" />
              </div>
            </div>

            <!-- Select, Radio, Checkbox -->
            <div v-if="['select', 'radio', 'checkbox'].includes(block.type)" class="q-ml-md">
              <draggable v-if="editMode" v-model="block.options" item-key="index" handle=".handle" class="row">
                <template #item="{ element: opt, index: optIndex }">
                  <div class="col-12 flex items-center q-mb-sm">
                    <QIcon v-if="editMode" name="uil:draggabledots" class="handle q-mr-xs" />
                    <QIcon :name="questIcons[block.type]" class="q-mr-xs" />
                    <QInput v-model="opt.label" placeholder="Option" borderless dense>
                      <template #append>
                        <HCButton
                          :icon="block.value === opt.value || (Array.isArray(block.value) && block.value.includes(opt.value)) ? 'uil:check' : 'uil:times'"
                          :color="block.value === opt.value || (Array.isArray(block.value) && block.value.includes(opt.value)) ? 'positive' : 'grey-5'"
                          label="Défaut"
                          size="sm"
                          @click="block.type === 'radio' ? block.value = optIndex : block.value = $_.xor(block.value ?? [], [optIndex])"
                        />
                      </template>
                    </QInput>
                  </div>
                </template>
              </draggable>
              <HCButton
                v-if="editMode"
                icon="uil:plus"
                label="Ajouter une option"
                force-label-on-xs
                size="sm"
                @click="block.options.push({ label: '', value: block.options.length })"
              />
              <QOptionGroup
                v-if="!editMode && ['radio', 'checkbox'].includes(block.type)"
                v-model="block.value"
                :type="block.type ?? 'checkbox'"
                :options="block.options ?? []"
                color="primary"
                size="sm"
                readonly
                disable
              />
              <QSelect
                v-if="!editMode && block.type === 'select'"
                v-model="block.value"
                :options="block.options ?? []"
                :multiple="block.multiple ?? false"
                map-options
                emit-value
                color="primary"
                outlined
                :label="`Sélectionner ${block.multiple ? 'vos réponses' : 'votre réponse'}`"
                size="sm"
                readonly
              />
            </div>

            <!-- Text -->
            <div v-if="block.type === 'text'" class="row q-col-gutter-sm">
              <!-- <QSelect
                v-if="editMode"
                v-model="block.textType"
                label="Type de saisie"
                class="col-12 col-md-6"
                :options="[
                  { label: 'Texte', value: 'text' },
                  { label: 'Email', value: 'email' },
                  { label: 'Numéro de téléphone', value: 'tel' },
                  { label: 'URL', value: 'url' },
                ]"
                outlined
                map-options
                emit-value
                dense
              /> -->
              <QInput
                v-if="editMode"
                v-model.number="block.maxlength"
                label="Longueur maximum (entre 20 et 1000)"
                type="number"
                class="col-12 col-md-6"
                outlined
                clearable
                dense
                :placeholder="$_.get(block, 'placeholder', undefined)"
                :rules="[val => (parseInt(val) >= 20 && parseInt(val) <= 1000) || 'Longueur incorrecte']"
                @clear="block.maxlength = 100"
              />
              <QInput
                v-if="editMode"
                v-model="block.placeholder"
                label="Texte de remplacement (facultatif)"
                class="col-12 col-md-6"
                outlined
                clearable
                dense
              />
              <QInput
                v-if="!editMode"
                v-model="block.value"
                :autogrow="block.autogrow"
                counter
                outlined
                :type="block.textType ?? 'text'"
                :placeholder="$_.get(block, 'placeholder', undefined)"
                :maxlength="block.maxlength"
                class="full-width"
                lazy-rules
                readonly
                dense
              />
            </div>

            <!-- Slider, Range -->
            <div v-if="['slider', 'range'].includes(block.type)" class="row q-col-gutter-sm">
              <template v-if="editMode">
                <QInput
                  v-model.number="block.min"
                  label="Minimum"
                  type="number"
                  class="col-3"
                  outlined
                  dense
                  :rules="[val => (parseInt(val) >= 0 && parseInt(val) < block.max) || 'Saisie incorrecte']"
                  :hint="`Plus que 0 et moins que ${block.max}`"
                />
                <QInput
                  v-model.number="block.max"
                  label="Maximum"
                  type="number"
                  class="col-3"
                  outlined
                  dense
                  :rules="[val => (parseInt(val) >= 0 && parseInt(val) > block.min) || 'Saisie incorrecte']"
                  :hint="`Plus que 0 et plus que ${block.min}`"
                />
                <QInput
                  v-model.number="block.step"
                  label="Pas"
                  type="number"
                  class="col-3"
                  outlined
                  dense
                  :rules="[val => (parseInt(val) >= 0 && parseInt(val) < block.max) || 'Saisie incorrecte']"
                  :hint="`Entre 0 et ${block.max}`"
                />
                <QInput
                  v-if="block.type === 'slider'"
                  v-model.number="block.value"
                  label="Défaut"
                  type="number"
                  class="col-3"
                  outlined
                  dense
                  :rules="[val => (parseInt(val) >= 0 && parseInt(val) <= block.max && parseInt(val) >= block.min) || 'Saisie incorrecte']"
                  :hint="`Plus que 0 et compris entre ${block.min} et ${block.max}`"
                />
                <QInput
                  v-if="block.type === 'range'"
                  v-model.number="block.value.min"
                  label="Défaut (min)"
                  type="number"
                  class="col"
                  outlined
                  dense
                  :rules="[val => (parseInt(val) >= 0 && parseInt(val) <= block.max && parseInt(val) >= block.min && parseInt(val) <= block.value.max) || 'Saisie incorrecte']"
                  :hint="`Plus que ${block.min}, moins que ${block.value.max} et moins que ${block.max}`"
                />
                <QInput
                  v-if="block.type === 'range'"
                  v-model.number="block.value.max"
                  label="Défaut (max)"
                  type="number"
                  class="col"
                  outlined
                  dense
                  :rules="[val => (parseInt(val) >= 0 && parseInt(val) <= block.max && parseInt(val) >= block.min && parseInt(val) >= block.value.min) || 'Saisie incorrecte']"
                  :hint="`Plus que ${block.value.min} et moins que ${block.max}`"
                />
              </template>
              <template v-else>
                <QSlider
                  v-if="block.type === 'slider'"
                  v-model="block.value"
                  :min="block.min ?? 0"
                  :max="block.max ?? 10"
                  :step="block.step ?? 1"
                  color="primary"
                  label-always
                  marker-labels
                  snap
                  readonly
                />
                <QRange
                  v-if="block.type === 'range'"
                  v-model="block.value"
                  :min="block.min ?? 0"
                  :max="block.max ?? 10"
                  :step="block.step ?? 1"
                  drag-range
                  label-always
                  marker-labels
                  snap
                  color="primary"
                  readonly
                />
              </template>
            </div>

            <!-- Rating -->
            <div v-if="block.type === 'rating'" class="row">
              <QInput
                v-if="editMode"
                v-model.number="block.max"
                label="Nombre de choix"
                type="number"
                class="col-3"
                outlined
                dense
                :rules="[val => (parseInt(val) >= 0 && parseInt(val) <= 20) || 'Saisie incorrecte']"
                hint="De 0 à 20"
              />
              <QRating
                v-else
                v-model="block.value"
                size="sm"
                :max="block.max ?? 5"
                color="primary"
                readonly
              />
            </div>
          </div>
        </template>
      </draggable>
    </QCardSection>
    <QSeparator v-if="editMode" class="q-mb-md full-width" />
    <QCardSection v-if="editMode" class="q-pt-lg">
      <div class="row text-grey-5 flex justify-between items-center">
        <AppContent path="communication.forms.add_question" />
        <HelpTooltip path="communication.forms.blocks.help" />
      </div>
      <div class="row">
        <div v-for="(questBlocksOption, index) of questBlocksOptions" :key="index" class="col-12 col-md-3 q-pa-sm">
          <div class="br-25 bd-1 bd-grey-4 q-pa-sm cursor-pointer flex items-center no-wrap" @click="quest.data.blocks.push($_.cloneDeep(questBlocksOption.block))">
            <QIcon :name="questIcons[questBlocksOption.block.type]" size="md" color="secondary" class="q-mr-sm" />
            <div>
              {{ questBlocksOption.label }}
            </div>
            <ActionTooltip path="prompt.generic_add_button" />
          </div>
        </div>
      </div>
      <div class="row flex-center">
        <HCBigButton v-if="!readonly && $_.get(quest, 'metadata.isForm', false)" class="q-ml-md" icon="uil:save" color="positive" label="prompt.save_button" @click="triggerSave" />
      </div>
    </QCardSection>
  </component>
</template>

<script>
import draggable from 'vuedraggable'
import QuestionnaireMixins from 'hc-core/mixins/questionnaire'
import HelpTooltip from 'hc-core/components/tooltips/help-tooltip'

export default {
  components: { draggable, HelpTooltip },
  mixins: [QuestionnaireMixins],
  props: {
    readonly: {
      type: Boolean,
      default: true
    },
    element: {
      type: Object,
      default: () => { return { data: { blocks: [] } } }
    },
    integrated: {
      type: Boolean,
      defualt: false
    }
  },
  emits: ['update:model-value'],
  data () {
    return {
      editMode: false,
      questBlocksOptions: [
        { label: this.$t('communication.forms.blocks.text'), block: { type: 'text', textType: 'text', autogrow: false, maxlength: 100 } },
        { label: this.$t('communication.forms.blocks.radio'), block: { type: 'radio', options: [] } },
        { label: this.$t('communication.forms.blocks.checkbox'), block: { type: 'checkbox', value: [], options: [] } },
        { label: this.$t('communication.forms.blocks.select'), block: { type: 'select', options: [], multiple: true } },
        { label: this.$t('communication.forms.blocks.rating'), block: { type: 'rating', max: 5 } },
        { label: this.$t('communication.forms.blocks.slider'), block: { type: 'slider', min: 0, max: 10, step: 1, value: 5 } },
        { label: this.$t('communication.forms.blocks.range'), block: { type: 'range', min: 0, max: 10, step: 1, value: { min: 3, max: 7 } } },
      ],
      quest: {}
    }
  },
  watch: {
    element: {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (val) this.quest = val
      }
    }
  },
  methods: {
    async triggerSave () {
      this.$emit('update:model-value', this.$_.pick(this.quest, ['label', 'data']))
    },
    async draggableEvent (evt) {
      // console.log(evt)
      // Trigger save
      // await this.triggerSave()
    },
  }
}
</script>
