import { concat, get, uniq, xor } from 'lodash'
import stelace from 'hc-core/composables/stelace'

export async function fetchElement ({ commit }, { id }) {
  const element = await stelace.assets.read(id)
  if (element) {
    commit('kanban/setKanbanData', { element }, { root: true })
    return element
  } else return null
}

export async function fetchApplications ({ commit }, { assetId, ownerId }) {
  const applicationsRequest = await stelace.forward.get('transactions/advanced', {
    ownerId,
    assetId,
    nbResultsPerPage: 500,
    page: 1
  })
  if (applicationsRequest.results.length) {
    commit('kanban/setKanbanData', { applications: applicationsRequest.results }, { root: true })
  } else return null
}

export async function fetchApplicants ({ commit }, { id }) {
  const applicantsRequest = await stelace.forward.get('/users/advanced', {
    id,
    nbResultsPerPage: 500,
    page: 1
  })
  if (applicantsRequest.results.length) {
    commit('kanban/setKanbanData', { applicants: applicantsRequest.results }, { root: true })
  } else return null
}

export async function fetchProfilesAssets ({ commit }, { id }) {
  const profilesRequest = await stelace.forward.get('/assets/advanced', {
    id,
    nbResultsPerPage: 500,
    page: 1
  })
  if (profilesRequest.results.length) {
    commit('kanban/setKanbanData', { profileAssets: profilesRequest.results }, { root: true })
  } else return null
}

export async function fetchConversations ({ commit, dispatch, rootGetters }, { interlocutorId }) {
  let inboxPage = 1
  let inboxRequest = {}
  let conversations = []
  do {
    inboxRequest = await dispatch('inbox/fetchInbox', {
      id: rootGetters['auth/currentNaturalUser'].id,
      nbResultsPerPage: 100,
      withMessages: true,
      interlocutorId,
    }, { root: true })
    conversations = concat(conversations, inboxRequest.results)
    inboxPage ++
  } while (inboxPage <= inboxRequest.nbPages)
  if (conversations.length) {
    commit('kanban/setKanbanData', { conversations }, { root: true })
  } else return null
}

export async function initKanban ({ dispatch, rootGetters }, { id }) {
  await dispatch('kanban/fetchElement', { id }, { root: true })

  const element = rootGetters['kanban/element']
  if (element) await dispatch('kanban/fetchApplications', { assetId: element.id, ownerId: rootGetters['auth/uElements']('targetId') }, { root: true })

  const applications = rootGetters['kanban/applications']
  if (applications.length) await dispatch('kanban/fetchApplicants', { id: uniq(applications.map(application => application.takerId)) }, { root: true })

  const applicants = rootGetters['kanban/applicants']
  if (applicants.length) {
    await dispatch('kanban/fetchProfilesAssets', { id: uniq(applicants.map(applicant => get(applicant, 'metadata._resume.profileAssetId', false))) }, { root: true })
    await dispatch('kanban/fetchConversations', { interlocutorId: uniq(applicants.map(a => a.id)) }, { root: true })
  }
}

export async function updateItem ({ commit, dispatch, rootGetters }, { type, transaction, transitionName, conversation, convMarkRead = true }) {
  try {
    if (type === 'applications' && transaction && transitionName) {
      const updated = await dispatch('asset/createTransactionTransition', { transaction: transaction, transitionName }, { root: true })
      if (updated) {
        commit('kanban/replaceKanbanArrayData', { key: type, value: updated }, { root: true })

      }
    } else if (type === 'conversations' && conversation) {
      // Only marking messages in database
      if (convMarkRead) {
        await dispatch('inbox/markConversationAsRead', {
          messages: get(conversation, 'messages', []).filter(m => !m.read && !rootGetters['auth/uElements']('implantations').map(i => i.id).includes(m.senderId))
        }, { root: true })
      }

      // Update in kanban referential
      const updated = await dispatch('inbox/fetchInbox', {
        id: rootGetters['auth/currentNaturalUser'].id,
        nbResultsPerPage: 1,
        withMessages: true,
        interlocutorId: conversation.interlocutorId,
      }, { root: true })
      if (updated && updated.results[0]) {
        commit('kanban/replaceKanbanArrayData', { key: type, value: updated.results[0], matchKey: 'interlocutorId' }, { root: true })
      }
    }

    // Check if need to reset stage unread filter status
    for (const stageValue of rootGetters['kanban/filterUnread']) {
      const filterStage = rootGetters['kanban/kanban'].stages.find(stage => stage.value === stageValue)
      if (filterStage && filterStage.nbUnread === 0 && filterStage.items.length === 0) {
        commit('kanban/setKanbanData', {
          filterUnread: xor(rootGetters['kanban/filterUnread'], [stageValue])
        }, { root: true })
      }
    }
  } catch (e) {
    console.log(e)
  }
}
