export default {
  computed: {
    stepsData () {
      return this.$_.orderBy(
        [
          {
            index: 10,
            icon: 'uil:comment',
            field: 'comment',
            fields: ['comment'],
            slug: 'comment',
            optional: false,
            component: 'TextInput',
            type: 'textarea',
            label: this.$t('component.forms.testimonialForm.title.comment'),
            menuLabel: this.$t('component.forms.testimonialForm.menu.comment')
          },
          {
            index: 9,
            icon: 'uil:music',
            field: 'metadata.testimonial.musicStyle',
            fields: ['metadata.testimonial.musicStyle'],
            slug: 'music',
            optional: true,
            component: 'TextInput',
            label: this.$t('component.forms.testimonialForm.title.musicStyle'),
            menuLabel: this.$t('component.forms.testimonialForm.menu.musicStyle')
          },
          {
            index: 8,
            icon: 'uil:tv-retro',
            field: 'metadata.testimonial.favoriteTvshow',
            fields: ['metadata.testimonial.favoriteTvshow'],
            slug: 'serie',
            optional: true,
            component: 'TextInput',
            label: this.$t('component.forms.testimonialForm.title.favoriteTvshow'),
            menuLabel: this.$t('component.forms.testimonialForm.menu.favoriteTvshow')
          },
          {
            index: 7,
            icon: 'uil:heart',
            field: 'metadata.testimonial.hobbies',
            fields: ['metadata.testimonial.hobbies'],
            slug: 'hobbies',
            optional: true,
            component: 'TextInput',
            label: this.$t('component.forms.testimonialForm.title.hobbies'),
            menuLabel: this.$t('component.forms.testimonialForm.menu.hobbies')
          },
          {
            index: 6,
            icon: 'uil:kid',
            field: 'metadata.testimonial.childs',
            fields: ['metadata.testimonial.childs'],
            slug: 'enfants',
            optional: true,
            component: 'TextInput',
            label: this.$t('component.forms.testimonialForm.title.childs'),
            menuLabel: this.$t('component.forms.testimonialForm.menu.childs')
          },
          {
            index: 5,
            icon: 'uil:users-alt',
            field: 'metadata.testimonial.sportTeam',
            fields: ['metadata.testimonial.sportTeam'],
            slug: 'equipe',
            optional: true,
            component: 'TextInput',
            label: this.$t('component.forms.testimonialForm.title.sportTeam'),
            menuLabel: this.$t('component.forms.testimonialForm.menu.sportTeam')
          },
          {
            index: 4,
            icon: 'uil:baseball-ball',
            field: 'metadata.testimonial.sport',
            fields: ['metadata.testimonial.sport'],
            slug: 'sport',
            optional: true,
            component: 'TextInput',
            label: this.$t('component.forms.testimonialForm.title.sport'),
            menuLabel: this.$t('component.forms.testimonialForm.menu.sport')
          },
          {
            index: 3,
            icon: 'uil:hourglass',
            field: 'metadata.testimonial.seniority',
            fields: ['metadata.testimonial.seniority'],
            slug: 'anciennete',
            optional: false,
            format: 'MM/YYYY',
            component: 'DateSelect',
            label: this.$t('component.forms.testimonialForm.title.seniority'),
            label2: this.$t('component.forms.testimonialForm.title.seniority'),
            menuLabel: this.$t('component.forms.testimonialForm.menu.seniority')
          },
          {
            index: 2,
            icon: 'uil:calender',
            field: 'metadata.testimonial.birthYear',
            fields: ['metadata.testimonial.birthYear'],
            slug: 'birthYear',
            optional: false,
            format: 'YYYY',
            component: 'DateSelect',
            label: this.$t('component.forms.testimonialForm.title.birthYear'),
            menuLabel: this.$t('component.forms.testimonialForm.menu.birthYear')
          },
          {
            index: 1,
            icon: 'uil:star',
            field: 'metadata.testimonial.role',
            fields: ['metadata.testimonial.role'],
            slug: 'role',
            optional: false,
            component: 'TextInput',
            label: this.$t('component.forms.testimonialForm.title.role'),
            menuLabel: this.$t('component.forms.testimonialForm.menu.role')
          },
          {
            index: 0,
            icon: 'uil:user',
            field: 'metadata.testimonial.firstname',
            fields: ['metadata.testimonial.firstname'],
            slug: 'prenom',
            optional: false,
            component: 'TextInput',
            label: this.$t('component.forms.testimonialForm.title.firstname'),
            menuLabel: this.$t('component.forms.testimonialForm.menu.firstname')
          }
        ].filter((step) => this.$_.get(step, 'if', true)),
        ['index'],
        ['asc']
      ).map((step, index) => {
        step.index = index
        return step
      })
      // Filter : in case some steps are conditionnal
      // OrderBy : ensure we keep the same order of steps even if some are removed by an 'if' condition
      // Map : in case of steps removal, keep an order of indexes so there is no hole
    },
  }
}
