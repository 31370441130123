<template>
  <div
    v-if="tableProp"
    class="fit"
  >
    <QTable
      ref="GenericTable"
      :rows="tableData.results"
      :columns="tableProp.columns"
      :visible-columns="tableProp.columns.filter(c => $_.get(c, 'visible', true)).map(c => c.name)"
      :loading="loading"
      table-header-class="text-primary"
      hide-pagination
      flat
      @request="onRequest"
    >
      <!-- Top -->
      <template #top>
        <div class="full-width">
          <div class="flex justify-between">
            <div class="flex items-center q-gutter-xs">
              <HCButton
                v-for="(button, i) of $_.get(tableProp, 'filters.buttons', [])"
                :key="i"
                :icon="button.icon"
                :color="button.color"
                :label="button.label"
                :tooltip="button.label"
                @click.capture.stop="button.fn ? button.fn(element) : null"
              />
              <QSelect
                v-for="(select, i) of $_.get(tableProp, 'filters.selects', [])"
                :key="i"
                :model-value="$_.get(filters, select.key, undefined)"
                :options="select.options"
                :label="select.label"
                map-options
                emit-value
                outlined
                dense
                :style="$_.get(select, 'style', undefined)"
                @update:model-value="$_.set(filters, select.key, $event); resetPagination()"
              />
              <Checkbox
                v-for="(checkbox, i) of $_.get(tableProp, 'filters.checkboxes', [])"
                :key="i"
                :model-value="$_.get(filters, checkbox.key, undefined)"
                :true-value="$_.get(checkbox, 'true-value', true)"
                :false-value="$_.get(checkbox, 'false-value', false)"
                :label="checkbox.label"
                @update:model-value="$_.set(filters, checkbox.key, $event);resetPagination()"
              />
            </div>
            <HCButton
              icon="uil:sync"
              tooltip="prompt.refresh_button"
              :loading="loading"
              :disable="loading"
              @click="refresh"
            />
          </div>
        </div>
      </template>

      <!-- Header / Columns labels -->
      <template #header="props">
        <QTr :props="props">
          <QTh
            v-for="col in props.cols"
            :key="col.name"
            :props="props"
            class="text-primary"
            :class="{ 'text-weight-medium': (filters.orderBy === col.name) || ($_.get(filters, 'customOrderConfig.orderBy', null) === col.customOrderField), 'cursor-pointer': col.customOrderField }"
            @click="onRequest(col)"
          >
            <span>
              {{ col.label }}
            </span>
            <QIcon
              v-if="(filters.orderBy === col.name) || ($_.get(filters, 'customOrderConfig.orderBy', null) === col.customOrderField)"
              color="primary"
              size="sm"
              :name="`uil:arrow-${{ asc: 'up', desc: 'down'}[pickFirstKey(filters, ['customOrderConfig.order', 'order'], 'desc')]}`"
            />
          </QTh>
        </QTr>
      </template>

      <template #body="props">
        <slot
          name="body"
          :props="props"
        />
      </template>

      <!-- Pagination -->
      <template #bottom>
        <div class="full-width flex justify-between">
          <QSelect
            v-model="tableData.nbResultsPerPage"
            borderless
            dense
            :options="[10, 20, 50, 100]"
            @update:model-value="refresh()"
          />
          <QPagination
            v-model="tableData.page"
            color="primary"
            :max="tableData.nbPages"
            :direction-links="true"
            :boundary-links="true"
            icon-first="uil:left-arrow-to-left"
            icon-last="uil:arrow-to-right"
            icon-prev="uil:arrow-left"
            icon-next="uil:arrow-right"
            size="sm"
            :input="true"
            @update:model-value="refresh()"
          />
        </div>
      </template>
      <template #pagination />

      <!-- Loading -->
      <template #loading>
        <QInnerLoading showing>
          <HCLoading />
        </QInnerLoading>
      </template>

      <!-- No data -->
      <template #no-data>
        <div class="full-width row flex-center text-negative q-gutter-sm">
          <QIcon
            size="2em"
            name="uil:confused"
          />
          <AppContent path="error.no_data" />
        </div>
      </template>
    </QTable>
  </div>
</template>

<script>
export default {
  props: {
    tableProp: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      filters: {},
      loading: false,
      tableData: {
        page: 1,
        nbResultsPerPage: 10,
      },
    }
  },
  async mounted () {
    this.setPagination()
    // Preselect default options
    for (const select of this.$_.get(this.tableProp, 'filters.selects', [])) {
      this.$_.set(this.filters, select.key, this.$_.get(select.options.find((o) => this.$_.get(o, 'default', false)), 'value', undefined))
    }
    for (const checkbox of this.$_.get(this.tableProp, 'filters.checkboxes', [])) {
      this.$_.set(this.filters, checkbox.key, this.$_.get(checkbox, 'default', undefined))
    }
    if (this.$_.get(this.tableProp, 'defaultFilters', false)) {
      this.$_.merge(this.filters, this.tableProp.defaultFilters)
    }
    if (this.$_.get(this.tableProp, 'refresh', false)) await this.refresh()
  },
  methods: {
    setPagination () {
      this.$refs.GenericTable.setPagination({
        sortBy: 'createdDate',
        descending: true,
        page: this.tableData.page,
        rowsPerPage: this.tableData.nbResultsPerPage,
        rowsNumber: this.tableData.nbResults
      })
    },
    async resetPagination (triggerRefresh = true) {
      this.$_.set(this.tableData, 'page', 1)
      // this.$_.set(this.tableData, 'nbResultsPerPage', 10)
      if (triggerRefresh) await this.refresh()
    },
    async refresh () {
      try {
        if (this.loading) return
        this.loading = true
        this.setPagination()
        if (this.$_.get(this.tableProp, 'refresh', false)) {
          this.tableData = this.$_.cloneDeep(await this.tableProp.refresh({
            ...this.filters,
            ...this.$_.pick(this.tableData, ['page', 'nbResultsPerPage']),
            ...this.tableProp.defaultParams ?? {}, // Last so it overrides
          }))
        }
      } catch (e) {
        this.useLogger(e)
      } finally {
        this.loading = false
      }
    },
    onRequest (props) {
      // this.$refs.GenericTable.requestServerInteraction()
      if (props.customOrderField) {
        const currentCustomOrder = this.$_.get(this.filters, 'customOrderConfig')
        if (currentCustomOrder && currentCustomOrder.orderBy === props.customOrderField) {
          this.$_.set(this.filters, 'customOrderConfig.order', { asc: 'desc', desc: 'asc' }[currentCustomOrder.order])
        } else {
          this.$_.set(this.filters, 'customOrderConfig.orderBy', props.customOrderField)
          this.$_.set(this.filters, 'customOrderConfig.order', 'desc')
        }
        this.refresh()
      }
    }
  }
}
</script>
