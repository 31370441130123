<template>
  <component
    :is="integrated ? 'div' : 'QDialog'"
    v-if="applicantDisplayerStore.showDialog && applicantDisplayerStore.applicantId"
    ref="ApplicantDialog"
    v-model="showDialog"
    transition-show="slide-left"
    transition-hide="slide-right"
    class="fit"
    :maximized="$q.screen.lt.md"
    full-width
    @hide="$emit('hide')"
  >
    <QCard class="fit overflow-hidden">
      <QCardSection
        id="ApplicantDialogCardHeader"
        class="bg-grey-1 text-primary"
      >
        <div class="flex no-wrap justify-between items-center">
          <div class="flex items-center">
            <AppAvatar
              size="40px"
              class="q-mr-md gt-sm"
              :entity="companyAsset ?? item.profileAsset ?? item"
            />
            <div
              v-if="isTargetOrganization"
              class="text-h6 text-weight-bold text-dark"
            >
              {{ $_.get(companyAsset, 'name', $_.get(item, 'displayName', '')) }}
            </div>
            <div v-else :class="['text-h6 text-weight-bold text-dark', { 'hc-blur non-selectable no-pointer-events': isRestricted }]">
              <div :class="$q.screen.lt.md ? 'column' : 'flex items-center q-gutter-x-sm'">
                <span>
                  {{ item.firstname ?? '' }}
                </span>
                <span>
                  {{ item.lastname ?? '' }}
                </span>
              </div>
              <div
                v-if="jobrefLabel && $_.get(item, 'profileAsset.customAttributes.verifiedProfile', false)"
                class="flex items-center"
              >
                <div class="text-caption text-grey-5 q-mr-sm">
                  {{ jobrefLabel }}
                </div>
                <QIcon
                  name="uil:check-circle"
                  color="positive"
                  size="sm"
                >
                  <ActionTooltip path="profile.profile_verified" />
                </QIcon>
              </div>
              <div v-if="!isRestricted && $q.screen.gt.sm" class="flex q-gutter-x-sm items-start">
                <HCButton
                  v-if="cptContact.phone"
                  :label="cptContact.phone"
                  icon="uil:phone-alt"
                  :size="$q.screen.lt.lg ? 'sm' : 'md'"
                  is-copy
                />
                <HCButton
                  v-if="cptContact.email"
                  :label="cptContact.email"
                  icon="uil:envelope-alt"
                  :size="$q.screen.lt.lg ? 'sm' : 'md'"
                  is-copy
                />
              </div>
            </div>
          </div>

          <div class="flex items-center no-wrap q-gutter-x-sm">
            <Select
              v-if="!applicantView && activeApplication"
              :model-value="activeApplication.status"
              :options="transitions.filter(({ slug }) => !['new', 'restricted'].includes(slug))"
              option-value="slug"
              option-label="label"

              :readonly="!isEditorOrAboveInOrg() || $_.isNil(transitions.find(({ slug }) => slug !== 'new' && slug !== 'restricted')) || activeApplication.status === 'restricted'"
              :disable="activeApplication.status === 'restricted'"
              label="Statut"
              :display-value="transitions.find(({ slug }) => slug === activeApplication.status).label"

              :label-color="transitions.find(({ slug }) => slug === activeApplication.status).accentColor"
              :bg-color="transitions.find(({ slug }) => slug === activeApplication.status).color"
              @update:model-value="$emit('handleEvent', {
                transaction: activeApplication,
                applicant: item,
                trsName: transitions.find(({ slug }) => slug === $event).transitionName
              })"
            />
            <HCMenu
              :actions="actions"
              expanded
              sub-menu-position="left"
            />
            <HCButton v-if="!integrated" v-close-popup is-close />
          </div>

        </div>
      </QCardSection>
      <QSeparator />

      <!-- Tabs -->
      <HCTabs
        v-if="!isRestricted"
        :model-value="applicantDisplayerStore.tab"
        :options="tabsOptions"
        @update:model-value="$store.commit('user/setAppDispProperty', { tab: $event })"
      />

      <!-- Panels -->
      <div
        v-if="!loading"
        :class="[
          'fit overflow-auto',
          { 'overflow-hidden': $q.screen.gt.sm },
          { 'q-pb-xl': bottomNavigation && applicantDisplayerStore.items.length > 1 && $q.screen.gt.sm },
          { 'hc-blur non-selectable no-pointer-events': isRestricted }
        ]"
        :style="cptPanelHeight"
      >
        <div v-if="applicantDisplayerStore.tab === 'files' && files.length > 0" class="fit">
          <!-- Contact -->
          <QCardSection
            v-if="!isRestricted && !$q.screen.gt.sm"
            class="flex items-center q-gutter-x-sm"
          >
            <span class="text-grey-5 text-weight-medium">
              Informations de contact
            </span>
            <QSpace class="lt-md full-width" />
            <HCButton
              v-if="cptContact.phone"
              :label="cptContact.phone"
              icon="uil:phone-alt"
              :size="$q.screen.lt.lg ? 'sm' : 'md'"
              is-copy
            />
            <HCButton
              v-if="cptContact.email"
              :label="cptContact.email"
              icon="uil:envelope-alt"
              :size="$q.screen.lt.lg ? 'sm' : 'md'"
              is-copy
            />
            <HCButton
              v-if="cptContact.linkedin"
              label="Linkedin"
              icon="uil:linkedin"
              :size="$q.screen.lt.lg ? 'sm' : 'md'"
              :href="cptContact.linkedin"
              target="_blank"
              force-label-on-xs
            />
          </QCardSection>
          <QSeparator v-if="!isRestricted" />
          <FilesViewer
            class="fit"
            :files="files"
            @uploaded="updateTransaction"
            @removed="updateTransaction"
          />
        </div>
        <Chat
          v-else-if="applicantDisplayerStore.tab === 'messages'"
          class="fit"
          :conversation="conversation"
          :application="activeApplication"
          :applicant="item"
          @refresh="$emit('refresh')"
        />
        <ApplicationsTable
          v-else-if="applicantDisplayerStore.tab === 'applications'"
          :applicant="isApplicant() ? currentNaturalUser : item"
          class="fit overflow-auto"
          :applicant-view="isApplicant()"
          @refresh="$emit('refresh')"
        />
        <QCardSection v-else-if="applicantDisplayerStore.tab === 'lists'" class="fit">
          <div class="flex justify-between">
            <AppContent class="text-grey-5" path="applicantList.gestion" />
            <HelpTooltip title="applicantList.page_title" path="applicantList.help" />
          </div>
          <QList separator>
            <QItem
              v-for="(applicantList, i) of $uElements('applicantList')"
              :key="i"
              class="row"
            >
              <QItemSection class="col-4">
                <QItemLabel class="text-weight-bold">
                  {{ $_.truncate($_.get(applicantList, 'data.label', $t('applicantList.label_undefined'))) }}
                </QItemLabel>
              </QItemSection>
              <QItemSection class="col-4">
                {{ $_.truncate($_.get(applicantList, 'data.description', $t('applicantList.description_undefined')), 70) }}
              </QItemSection>
              <QItemSection class="col-4" side>
                <HCButton
                  v-if="isApplicantInList(item.id, applicantList) && !$_.get(applicantList, 'metadata.unremovable', false)"
                  label="applicantList.list_remove"
                  color="negative"
                  icon="uil:times"
                  @click="toggleApplicantOfList(item.id, applicantList.id)"
                />
                <HCBigButton
                  v-else-if="!isApplicantInList(item.id, applicantList) && !$_.get(applicantList, 'metadata.unremovable', false)"
                  label="applicantList.list_add"
                  color="positive"
                  icon="uil:plus-circle"
                  @click="toggleApplicantOfList(item.id, applicantList.id)"
                />
                <HCButton
                  v-else-if="$_.get(applicantList, 'metadata.unremovable', false)"
                  label="applicantList.list_unremovable"
                  color="grey-4"
                />
              </QItemSection>
            </QItem>
            <QItem v-if="isSubscribed()">
              <QItemSection class="flex flex-center">
                <HCBigButton
                  label="applicantList.create_title"
                  color="positive"
                  icon="uil:plus-circle"
                  @click="createList()"
                />
              </QItemSection>
            </QItem>
          </QList>
        </QCardSection>
        <HCLoading :showing="loading" inner />
      </div>

      <!-- Restricted Panel -->
      <div
        v-if="isRestricted"
        class="absolute absolute-center z-top"
      >
        <QCard class="br-25 bg-accent">
          <QCardSection v-if="lockCvtheque" class="text-center q-gutter-y-sm">
            <AppContent
              path="component.dialogs.applicant_dialog.lock_cvtheque_title"
              class="text-weight-bold"
            />
            <AppContent path="component.dialogs.applicant_dialog.lock_cvtheque_hint" :options="{ cvthequeCredits: $uElements('cvthequeCredits'), cvthequeCreditsAfter: $uElements('cvthequeCredits') - 1 }" />
            <HCBigButton
              v-if="$uElements('cvthequeCredits') - 1 >= 0"
              label="component.dialogs.applicant_dialog.lock_cvtheque_unlock"
              :loading="loadingUnlock"
              @click="handleUnlock()"
            />
            <HCBigButton
              v-else
              label="shop.cta.subscribe"
            />
          </QCardSection>
          <QCardSection v-else class="text-center q-gutter-y-sm">
            <AppContent
              path="component.dialogs.applicant_dialog.restricted_title"
              class="text-weight-bold"
            />
            <AppContent path="component.dialogs.applicant_dialog.restricted_hint" />
            <HCBigButton
              :to="{ name: 'shop' }"
              label="shop.cta.subscribe"
            />
          </QCardSection>
        </QCard>
      </div>

      <!-- Bottom navigation -->
      <QSeparator v-if="bottomNavigation && applicantDisplayerStore.items.length > 1" />
      <div
        v-if="bottomNavigation && applicantDisplayerStore.items.length > 1"
        class="q-pt-sm bg-grey-1 row"
        style="height: auto"
      >
        <div class="col-4 text-left q-pl-xs">
          <HCButton
            v-if="applicantDisplayerStore.canGoPrev"
            icon="uil:angle-left-b"
            :label="$q.screen.gt.xs ? 'Candidat précédent' : 'Précédent'"
            force-label-on-xs
            @click="$store.commit('user/setAppDispProperty', 'goPrevItem')"
          />
        </div>
        <div class="col-4 text-center">
          <HCButton
            is-close
            label="Quitter"
            @hide="$store.commit('user/setAppDispProperty', { showDialog: false })"
          />
        </div>
        <div class="col-4 text-right q-pr-xs">
          <HCButton
            v-if="applicantDisplayerStore.canGoNext"
            icon-right="uil:angle-right-b"
            :label="$q.screen.gt.xs ? 'Candidat suivant' : 'Suivant'"
            force-label-on-xs
            @click="$store.commit('user/setAppDispProperty', 'goNextItem')"
          />
        </div>
      </div>
    </QCard>
  </component>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { dlKeyFromS3 } from 'hc-core/composables/aws.js'
import { arrayIfFilter, pickFirstKey, sanitizeFilename } from 'hc-core/composables/misc.js'
import { transitions, updateTransactionStatus } from 'hc-core/composables/transaction.js'
import ApplicantListMixins from 'hc-core/mixins/applicant-list.js'
import HCMenu from 'hc-core/components/common/hc-menu'
import HCTabs from 'hc-core/components/common/hc-tabs'
const Chat = defineAsyncComponent(() => import('hc-core/components/tools/chat'))
const FilesViewer = defineAsyncComponent(() => import('hc-core/components/files/files-viewer'))
const HelpTooltip = defineAsyncComponent(() => import('hc-core/components/tooltips/help-tooltip'))
const ApplicationsTable = defineAsyncComponent(() => import('hc-core/components/tables/applications-table'))

export default {
  components: { HCMenu, HCTabs, Chat, HelpTooltip, FilesViewer, ApplicationsTable },
  mixins: [ApplicantListMixins],
  props: {
    // Would be better if autonomous based on this only
    applicantId: {
      type: String,
      required: true,
      default: null
    },
    integrated: {
      type: Boolean,
      default: false
    },
    applicantView: {
      type: Boolean,
      default: false
    },
    // If in context of CVTheque, base restricted status on presence in CVThqueList
    lockCvtheque: {
      type: Boolean,
      default: false
    },
    bottomNavigation: {
      type: Boolean,
      default: false
    },
  },
  emits: ['chipClick', 'hide', 'refresh', 'handleEvent'],
  data () {
    return {
      showDialog: true,
      loading: true,
      companyAsset: null,

      // Sizing
      observer: null,
      headerHeight: 80,
      transitions,
    }
  },
  computed: {
    applicantDisplayerStore () { return this.$store.state.user.applicantDisplayerStore },

    // Stelace user, with added properties (CC'ed from ApplicantCard) : restricted<Boolean>, applications<Array>, profileAsset<Asset of Profile type>
    item () { return this.applicantDisplayerStore.items.find(i => i.id === this.applicantId) },

    isTargetOrganization () { return this.item.id.startsWith('org_') },
    isHcMember () {
      const usersById = this.$store.getters['inbox/inbox'].usersById
      return (usersById[this.applicantId] && this.$_.has(usersById[this.applicantId], 'organizations.org_Gw8IMgE1qff1lTEpMqff')) || this.$_.has(this.item, 'organizations.org_Gw8IMgE1qff1lTEpMqff')
    },
    cptPanelHeight () { return `height:100%; max-height:calc(100% - ${this.headerHeight}px)` },
    cptPanelChatHeight () { return `height:100%; max-height:calc(100% - ${this.headerHeight}px - 150px);` },

    // Restricted is not paid client OR (inCVTheque AND not Premium)
    isRestricted () {
      const itemApplicationsHasRectricted = this.$_.uniqBy(
        this.$_.compact(
          this.$_.concat(
            this.activeApplication,
            this.$_.get(this.item, 'applications', []),
            this.$store.getters['kanban/applications'].filter(app => app.takerId === this.applicantId),
            this.$uElements('applications').filter(app => app.takerId === this.applicantId),
          )
        ),
        'id'
      ).filter(a => a.status === 'restricted').length
      return this.$_.get(this.item, 'isRestricted', false) || itemApplicationsHasRectricted || (this.lockCvtheque && (!this.isPremium() && !this.isCVThequeUnlocked(this.item.id)))
    },
    conversation () {
      const inboxState = this.$store.state.inbox
      const fromInbox = Object.values(inboxState.inbox.conversationsById).find(c => c.interlocutorId === this.item.id)
      if (fromInbox) return fromInbox
      const fromUserInbox = this.$_.get(inboxState, 'userInbox.results', []).find(c => c.interlocutorId === this.item.id)
      if (fromUserInbox) return fromUserInbox
      return { interlocutorId: this.item.id, topicId: this.$store.getters['inbox/talkAssets'].userTopicId }
    },
    tabsOptions () {
      if (this.isHcMember) return []
      return [
        { label: this.$t('pages.messages.tabs.files'), name: 'files', if: !this.isApplicant() },
        { label: this.$t('pages.messages.tabs.messages'), name: 'messages' },
        { label: this.$t('pages.messages.tabs.applications'), name: 'applications', if: this.$route.name !== 'cvtheque' },
        { label: this.$t('pages.messages.tabs.lists'), name: 'lists', if: this.isSubscribed() && this.$route.name !== 'embedOffer' },
      ]
    },
    actions () {
      return [
        {
          if: !this.isRestricted && this.isSubscribed(),
          icon: 'uil:star',
          label: this.favorite ? 'applicantList.fav_remove' : 'applicantList.fav_add',
          iconColor: this.favorite ? 'gold-4' : 'grey-6',
          color: this.$q.screen.gt.sm ? this.favorite ? 'gold-4' : 'grey-6' : undefined,
          fn: () => this.toggleApplicantOfList(this.$_.get(this.item, 'id', false)),
        },
        {
          if: !this.isRestricted && this.cptCvProps.cvPath !== null && !this.cptCvProps.cvExpired,
          icon: 'uil:import',
          label: 'pages.profile.cv_download',
          iconColor: 'positive',
          color: this.$q.screen.gt.sm ? 'positive' : undefined,
          fn: () => dlKeyFromS3({ type: 'pdf', key: this.cptCvProps.cvPath, dlLabel: `CV_${this.$_.get(this.item, 'firstname', '')}_${this.$_.get(this.item, 'lastname', '')}` }),
        }
      ]
    },
    activeApplication () {
      return this.$_.get(
        this.$_.sortBy(
          this.$_.uniqBy(
            this.$_.concat(
              this.$uElements('applications'),
              this.$store.getters['kanban/applications'],
              this.$_.get(this.item, 'applications', []),
            ),
            'id'
          ).filter((app) => app.takerId === this.item.id && app.assetId === this.$route.params.id),
          ['updatedDate'],
          ['desc']
        ),
        '[0]',
        null
      )
      // return this.$_.get(this.$uElements('applications').filter((app) => app.takerId === this.item.id && app.assetId === this.$route.params.id), '[0]', this.$_.get(this.item, 'applications[0]', null))
    },
    favorite () { return this.isFavorite(this.item.id) },
    cptCvProps () {
      const cvPath = pickFirstKey(this.item, ['profileAsset.metadata._files.resume', 'metadata._files.resume', 'resume.metadata._files.resume', 'metadata._resume.file', 'metadata._resume.indeed.file'], false) // First two are legits, others are relinquats
      const cvUpdate = pickFirstKey(this.item, ['profileAsset.customAttributes.resumeUpdate', 'profileAsset.metadata._files.resumeUpdate', 'metadata._files.resumeUpdate'], false)
      const expiredDate = new Date()
      expiredDate.setFullYear(expiredDate.getFullYear() - 2)
      expiredDate.setDate(expiredDate.getDate() - 1 )
      const cvExpired = !cvPath || pickFirstKey(this.item, ['profileAsset.metadata._files.resumeExpired', 'metadata._files.resumeExpired'], false) || (cvUpdate && new Date(cvUpdate) <= expiredDate)
      return { cvPath, cvUpdate, expiredDate, cvExpired }
    },
    files () {
      const app = `${this.$_.get(this.item, 'firstname', '')}_${this.$_.get(this.item, 'lastname', '')}`
      const trnApp = `${this.$_.get(this.activeApplication, 'assetSnapshot.name', '')}_${app}`
      const uploadConfig = {
        id: this.$_.get(this.activeApplication, 'id', null),
        entity: this.activeApplication ?? null,
        label: 'profile.trnfile.add',
        hint: 'profile.trnfile.add_hint',
        deleteTitle: 'profile.trnfile.delete_title',
        deleteMessage: 'profile.trnfile.delete_message',
        uploadFolder: 'files/trnfile',
        uploadPrefix: 'trnfile',
        contentType: 'application/pdf',
        useEntityId: true,
        field: 'metadata._files.trnfile',
      }

      return arrayIfFilter([
        {
          type: 'pdf',
          label: 'profile.resume.label',
          dlLabel: sanitizeFilename(`CV_${app}`),
          key: this.cptCvProps.cvPath,
          if: !!this.cptCvProps.cvPath && !this.cptCvProps.cvExpired,
          preventDl: !this.isCVThequeUnlocked(this.item.id)
        },
        {
          type: 'expiredResume',
          label: 'profile.resume.label',
          dlLabel: sanitizeFilename(`CV_${app}`),
          key: 'expiredResume',
          if: this.cptCvProps.cvExpired,
        },
        {
          type: 'pdf',
          label: 'profile.letter.label',
          dlLabel: sanitizeFilename(`Lettre_motivation_${trnApp}`),
          key: this.$_.get(this.activeApplication, 'metadata._files.letter', false),
          if: this.$_.get(this.activeApplication, 'metadata._files.letter', false)
        },
        {
          type: 'pdf',
          label: 'profile.trnfile.label',
          dlLabel: sanitizeFilename(`Candidature_${trnApp}`),
          key: this.$_.get(this.activeApplication, 'metadata._files.trnfile', false),
          if: this.$_.get(this.activeApplication, 'metadata._files.trnfile', false),
          allowRemove: true,
          uploadConfig,
        },
        {
          type: 'upload',
          label: 'profile.trnfile.add',
          uploadConfig,
          if: this.activeApplication && !this.$_.get(this.activeApplication, 'metadata._files.trnfile', false)
        }
      ])
    },
    jobrefLabel () {
      let val = this.$_.get(this.item, 'profileAsset.customAttributes.jobReferential', null)
      if (val) val = this.$_.get(this.getCustomAttributeValues('jobReferential').find(jr => jr.value === val), 'label', null)
      else val = this.$_.get(this.item, 'metadata._resume.preferredJob', null)
      return val
    },
    cptContact () {
      return {
        phone: pickFirstKey(this.item, ['profileAsset.metadata._resume.phone', 'metadata._private.phone'], null),
        email: pickFirstKey(this.item, ['profileAsset.metadata._resume.email', 'email'], null),
        linkedin: pickFirstKey(this.item, ['profileAsset.metadata._resume.links.linkedin'], null),
      }
    },
  },
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler: async function (val) {
        if (val) {
          this.loading = true
          if (this.isTargetOrganization) {
            const companyAssetRequest = await this.$store.dispatch('asset/list', {
              assetTypeId: this.$store.getters['common/companyTypeId'],
              ownerId: val.id,
              nbResultsPerPage: 1
            })
            this.companyAsset = this.$_.get(companyAssetRequest, '[0]', null)
          }

          if (this.lockCvtheque && !this.isCVThequeUnlocked(this.item.id) && this.isPremium()) {
            this.$emit('chipClick', {
              // Default, doubled since both used by kanbanv2 and applicantsDisplay, but not same handling
              item: this.$_.set(this.item, 'isRestricted', this.isRestricted),
              value: this.$_.set(this.item, 'isRestricted', this.isRestricted),
              chip: 'files',
              action: 'askUnlock',
            })
          }
          this.$nextTick(() => {
            this.observer = new ResizeObserver(() => {
              // this.headerHeight = this.$_.get(document.getElementById('ApplicantDialogCardHeader'), 'clientHeight', 80) + 2 * 16 /* q-pa-md */ + 36 /* HCTabs */ + 12 /* manual adjustement */
              this.headerHeight = this.$_.get(document.getElementById('ApplicantDialogCardHeader'), 'clientHeight', 80) + 37 /* HCTabs (36) + QSeparator (1) */ + (this.bottomNavigation && this.applicantDisplayerStore.items.length > 1 ? 40 : 0) + 5 /* manual adjustement */
            })
            if (document.getElementById('ApplicantDialogCardHeader')) {
              this.observer.observe(document.getElementById('ApplicantDialogCardHeader'))
            }
            this.loading = false
          })
        }
      }
    },
    // applicantId: {
    //   deep: true,
    //   immediate: true,
    //   handler: function (val) {
    //     if (val) this.fetchApplicant()
    //   }
    // },
  },
  created () {
    this.loading = false
  },
  unmounted () {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    dlKeyFromS3,
    updateTransactionStatus,
    async updateTransaction (uptTrn) {
      await this.$store.dispatch('transaction/update', uptTrn)
      this.$emit('hide')
      this.$emit('refresh')
      this.notifySuccess()
    },
    // async fetchApplicant () {
    //   try {
    //     this.loading = true
    //     // fetch applicant with all related data
    //     const applicant = await this.$store.dispatch('user/read', { id: this.applicantId })
    //     const applications = await this.$store.dispatch('transaction/list', {
    //       takerId: applicant.id,
    //       ownerId: this.$uElements('targetId')
    //     })
    //     this.applicant = this.$_.set(applicant, 'applications', this.$_.get(applications, 'results', []))
    //     // resutls
    //   } catch (e) {
    //     this.useLogger(e)
    //   } finally {
    //     this.loading = false
    //   }
    // },
  },
}
</script>

<style scoped>
.hc-blur {
  filter: blur(20px) grayscale(80%) brightness(80%);
}
</style>
